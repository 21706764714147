(function ($) {

  $.validator.addMethod("passcheck", function (value) {
    return /^(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[0-9]{1,})(?=.*[~!@#$%^&*()\-_=+{};:,<.>]{1,}).{8,}$/.test(value) && /[a-z]/.test(value) && /\d/.test(value)
  });

  // Change user password after reset. Why is this firing twice?
  $('#setNewPassword').off('submit').on('submit', function (e) {
    
    
    e.preventDefault()
    // $(this).removeAttr("novalidate");
    // e.stopImmediatePropagation()

    const form = $(this).serializeObject()
    if($(this).valid()) {
      Account.changeAccountPasswordOnExpire(form)
      .then((response) => {
        const parse = JSON.parse(response)
        const parse2 = JSON.parse(parse)
        console.log("after change password", parse2.response.result.status )

        if(parse2.response.result.status == '0') {
          console.log("modal should pop")
          $('#password_resetter').fadeOut('fast')
          const button = [{
            id: 'pw_changeConfirm',
            class: 'btn btn-primary',
            text: 'Ok',
          }]
          modalMessageManager('#page_modal', 'Password Change Confirmed', 'Your password is updated. You can now login with your new password', button)
        } else {
          if(parse2.response.result.status == '2') {
            console.log("well...?", parse2)
            const message = '<span class="error"> It appears you\'ve entered the password already stored. Would you prefer to <a href="/login/">log in</a>?</span>'
            $('#password_resetter #passwordChangeResponse').html(message)
          }
          
        }
      })
    }
  })

  $(document).ready(function() {
    $('.account-alert, .default-selection, #accountModalOverlay').fadeOut(function () {
      $(this).css('opacity', '1');
    })
    if (isPage('account')) {
      Account.initAccountPage()
        .then((response) => {
          const final = JSON.parse(response)
          const final2 = JSON.parse(final)

          // console.log("init account page:", JSON.parse(final))

          Account.users = final2.data[""].Users
          Account.defaultContact = Account.getDefaultContactId(final2.data[""].Contacts)
          Account.defaultAddress = Account.getDefaultAddressId(final2.data[""].Addresses)
          Account.customer = final2.data[""].Customer
          Account.defaultUser = Account.getDefaultUser()
        })
        .catch((error) => {
          console.log(error)
        })

        const content = Account.buildAccountMenu();
        $('#account_menu').html(content);
      }
    
      $('#setNewPassword').validate({
        rules: {
          tempPassword: "required",
          newPassword: {
            required: true,
            minlength: 8,
            passcheck: true
          },
          confirmNewPassword: {
            required: true,
            minlength: 8,
            equalTo: "#newPassword"
          }
        },
        messages: {
          newPassword: {
            required: "Please provide a password",
            minlength: "Your password must be at least 8 characters long",
            passcheck: "Your password requires at least upper case, one lower case, and one special character."
          },
          confirmNewPassword: {
            required: "Please provide a password",
            minlength: "Your password must be at least 8 characters long",
            equalTo: "Please enter the same password as above"
          }
        }
      });

  })
  $(document.body).on('click', 'a.menu-item', function (e) {
    e.preventDefault();
    const loadPanel = $(this).data('load')
    $('#account_menu').removeClass('active');
    $('#account_menu').addClass('closed');
    Account.manageAccountMenu(loadPanel)
  });


  $('#nav-toggle-account').click(function (e) {
    e.preventDefault();
    $('#account_menu').toggleClass('closed');
    $('#account_menu').toggleClass('active');
  })

  $(document.body).on('click', '#changeDefaultAddress', function (e) {
    e.preventDefault()
    Account.renderDefaultAddressForm()
  })

  $(document.body).on('click', '#changeDefaultContact', function (e) {
    e.preventDefault()
    Account.renderDefaultContactForm()
  })


  $(document.body).on('click', '.default-selection__close', function (e) {
    e.preventDefault()
    Account.closeDefaultModal()
  })


  $(document.body).on('click', '.button-options > .account-alert__close', function (e) {
    e.preventDefault()
    Account.closeAccountModal()
  })

  // edit an address or contact entry.
  $('#account_information_layout').on('click', 'li a.edit', function (e) {
    e.preventDefault();
    let id = $(this).data('edit');
    if ($(this).hasClass('contact')) {
      Account.editAccount(id, 'contact');
    } else if ($(this).hasClass('address')) {
      Account.editAccount(id, 'address');
    }
  })

  // Submitting an edited contact
  $(document.body).on('submit', '#edit_account_contact', function (e) {
    e.preventDefault();
    const form = $(this).serializeObject()
    const formid = $(this).data('id')
    Account.sendEditContact(form, formid)
      .then((response) => {
        // console.log("response after edit contact:", JSON.parse(response))

      })
      .then(() => {
        refreshAccountInfo()
      })
      .catch((error) => {
        console.log(error)
      })
  })

  $(document.body).on('submit', '#changeDefault', function (e) {
    e.preventDefault()
    const newDefault = $(this).find('input[type=radio]:checked').val()
    const type = $(this).find('input[type=radio]:checked').data('type')
    // console.log("New default", newDefault)
    Account.sendNewDefault(type, newDefault)
      .then((response) => {
        const parsed = JSON.parse(response)
        // console.log(parsed)
        Account.closeDefaultModal()
      })
      .then(() => {
        refreshAccountInfo()
      })
      .catch((error) => {
        console.log(error)
      })
  })

  // Submitting an edited address
  $(document.body).on('submit', '#edit-account-address', function (e) {
    e.preventDefault();
    const form = $(this).serializeObject()
    const formid = $(this).data('id')
    Account.sendEditAddress(form, formid)
      .then((response) => {
        // console.log("response after edit address:", JSON.parse(response))
        refreshAccountInfo()
      })
  })

  $(document.body).on('submit', '#communication_prefs_form', function (e) {
    e.preventDefault()
    const commprefs = []
    if ($('#communication_prefs_form #comm_optin_1').is(':checked')) {
      commprefs.push(1)
    }
    if ($('#communication_prefs_form #comm_optin_2').is(':checked')) {
      commprefs.push(2)
    }
    if ($('#communication_prefs_form #comm_optin_6').is(':checked')) {
      commprefs.push(6)
    }
    Account.sendCommPrefs(commprefs)
      .then((response) => {
        const title = "Communication Preferences"
        const message = "Your changes have been saved. It may take a few minutes for the website to display your updates."
        const buttons = '<button class="account-alert__close">Close</button>'
        Account.displayAccountModal(title, message, buttons)
      })
  })

  $(document.body).on('click', '.item-delete__confirm', function (e) {
    e.preventDefault()
    const item = $(this).data('item')
    const type = $(this).data('type')
    Account.sendItemForDelete(type, item)
  })



  $(document.body).on('click', '#changeDefaultAddress', function (e) {
    e.preventDefault()
    Account.renderDefaultAddressForm()

  })

  $(document.body).on('click', '#changeDefaultContact', function (e) {
    e.preventDefault()
    Account.showChangeDefault('contacts')

  })

  // Delete an address
  $(document.body).on('click', '.delete.address', function (e) {
    e.preventDefault()
    const id = $(this).data('id')
    Account.sendDeleteAddress(id)
      .then(() => {
        refreshAccountInfo()
      })
  })

  // Delete a contact
  $(document.body).on('click', '.delete.contact', function (e) {
    e.preventDefault()
    const id = $(this).data('id')
    Account.confirmDeletion('contact', id)
  })

  $(document.body).on('click', '.delete.address', function (e) {
    e.preventDefault()
    const id = $(this).data('id')
    Account.confirmDeletion('address', id)
  })

  // Cancel editing a contact or address
  $('#account_information_layout').on('click', 'button.cancel-edit', function (e) {
    e.preventDefault();
    $(this).closest('.editForm').html(' ');
  });

  // Opens a new address or contact form
  $('#account_information_layout').on('click', 'a.addItem', function (e) {
    e.preventDefault();
    if ($(this).hasClass('addAddress')) {
      $.get('/wp-content/themes/ct-2017/html_snippets/account_address_form.html', function (form) {
        $('.addAddressForm').html(form);
      });
    } else if ($(this).hasClass('addContact')) {
      $.get('/wp-content/themes/ct-2017/html_snippets/account_contact_form.html', function (form) {
        $('.addContactForm').html(form);
      });
    }
  })

  // Sends new address
  $(document.body).on('submit', '#add_account_address', function (e) {
    e.preventDefault()
    const form = $(this).serializeObject()
    Account.addAccountAddress(form)
      .then((response) => {
        // console.log("after add address: ", response)
        refreshAccountInfo()
      })
  })

  // Sends new contact
  $(document.body).on('submit', '#add_account_contact', function (e) {
    e.preventDefault()
    const form = $(this).serializeObject()
    Account.addAccountContact(form)
      .then((response) => {
        // console.log("after add address: ", response)
        refreshAccountInfo()
      })
  })

  // Change user password
  $(document.body).on('submit', '#changeAccountPassword', function (e) {
    e.preventDefault()
    const form = $(this).serializeObject()
    Account.changeAccountPassword(form)
      .then((response) => {
        // console.log("after change password", response)

      })
  })

  

  $('#newAccountSubmit').click(function (e) {
    e.preventDefault();
    $('#newAccountForm').validate({
      rules: {
        first_name: "required",
        last_name: "required",
        street_address1: "required",
        city: "required",
        state: "required",
        zip: "required",
        country: {
          required: true,
        },
        password: {
          required: true,
          minlength: 8,
          passcheck: true
        },
        confirm_password: {
          required: true,
          minlength: 8,
          equalTo: "#password"
        },
        email: {
          required: true,
          email: true
        },
      },
      messages: {
        first_name: "Please enter your first name",
        last_name: "Please enter your last name",
        password: {
          required: "Please provide a password",
          minlength: "Your password must be at least 8 characters long",
          passcheck: "Your password requires at least upper case, one lower case, and one special character."
        },
        confirm_password: {
          required: "Please provide a password",
          minlength: "Your password must be at least 8 characters long",
          equalTo: "Please enter the same password as above"
        },
        email: "Please enter a valid email address",
      }
    });
  });

  function loadAccountContent(contentName) {
    // return new Promise((resolve, reject) => {
    //   jQuery.ajax({
    //     url: ct_localize.ajaxuri,
    //     type: 'POST',
    //     data: {action: 'av_loadAccountContent', content: contentName },
    //     success: (response) => {
    //       resolve(response);
    //     },
    //     error: (error) => {
    //       reject(error);
    //     }
    //   });
    // });
  }

  function parseLoginResponse(data) {
    // console.log("Parse DATA: ", data);
    if (data.response.data.return_url) {
      window.location.href = data.response.data.return_url;
    }
    $('.response').html(data);

  }

  function editContact(contactID) {
    // console.log("edit: ", contactID);
    jQuery.get('/wp-content/themes/ct-2017/html_snippets/account_contact_form.html', function (form) {
      $('li#' + contactID + ' > .editForm').html(form);
    });
  }

  function editAddress(addressID) {
    // console.log("edit: ", addressID);
    jQuery.get('/wp-content/themes/ct-2017/html_snippets/account_address_form.html', function (form) {
      $('li#' + addressID + ' > .editForm').html(form);
    });
  }
}(jQuery))