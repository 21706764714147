const Order = {

    orderCompleted(finalOrder) {
        // clear order_id otherwise we get a loop trying to repurchase the same seats.

        localStorage.removeItem('order_id')
        localStorage.removeItem('order_session')
        Order.order_id = undefined

    },

    setOrderExpiration(time) {
        const expires = new Date(time)
        // console.log("expire time", expires.getTime() + 80 * 60000)
        return (expires.getTime() + 80 * 60000)
    },

    getFinalTicketCount(admissions) {
        const finalCount = Object.values(admissions).filter((admission) => {
            if(admission.hasOwnProperty('admission_id') && admission.state == '24') {
                return admission
            }
        })
    },
    av_getInfoOnOrder() {
        return window.callAPI('getInfoOnOrder call', false, {
            action: 'orderMethods',
            method: 'av_getInfoOnOrder',
            order_id: Order.order_id
        })
    },

    setOnHoldTimer(exp_time) {
        const countDownDate = new Date(exp_time).getTime()

        // console.log("countdowndate:", countDownDate)
        const x = setInterval(() => {
            const now = new Date().getTime()
            
            const distance = countDownDate - now
            // console.log("distance", distance)
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = Math.floor((distance % (1000 * 60)) / 1000)
            if (seconds < 10) {
                seconds = "0" + seconds
            }

            jQuery('.onHoldTimer').addClass('active');
            jQuery('.onHoldTimer .timer').text(minutes + ":" + seconds)

            if (distance < 0) { 
                clearInterval(x)
                jQuery('.onHoldTimer .content').html("Your seats have been released.")
                jQuery('.onHoldTimer .timer').text("--:--")
                localStorage.removeItem('order_id')
                localStorage.removeItem('order_session')
            }
        }, 1000)
    },

    getCitiesList() {
        return window.callAPI('getting lists', false, {
            action: 'av_getContent',
            method: 'av_getCitiesList'
        })
    },

    getAllLists() {
        return window.callAPI('getting all lists', false, {
            action: 'av_getContent',
            method: 'av_getAllLists'
        })
    },

    getPayMethods() {
        return window.callAPI('get pay methods', false, {
            action: 'av_getContent',
            method: 'av_getPayMethods'
        })
    },

    getGifts() {
        return window.callAPI('getting gifts', false, {
            action: 'av_getContent',
            method: 'av_getGifts'
        })
    },

    resetCustomerOnOrder(order) {
        if(order.hasOwnProperty('customer_id') && order.customer_id.standard) {

            const data = { action: 'orderMethods', method: 'av_resetCustomer'}
            const result = window.callAPI('removed Customer', true, data)
            return result

        } else {

            const error = { message: "no customer on order"}
            return error
        }
        
    },

    updateAfterLogin(updateData) {
        const result = window.callAPI('after login', true, updateData)
        return result
    },

    updateWorkingAccountOrder(workingData) {
        const result = window.callAPI('update working Account order', true, workingData)
        return result
    },
    updateWorkingGuestOrder(workingData) {
        const result = window.callAPI('update working Guest order', true, workingData)
        return result
    },
    removeSeatFromOrder(performance_id, seat_id) {
        const dataString = {
            action: 'orderMethods',
            method: 'av_setRemoveSeats',
            performance: performance_id,
            seat: seat_id
        }
        const result = window.callAPI("removed seat" + seat_id, true, dataString)
        return result
    },

    removeAdmissionFromOrder(performance_id, admission_id) {
        const dataString = {
            action: 'orderMethods',
            method: 'av_setRemoveAdmission',
            performance: window.performance_id,
            admission: admission_id
        }
        const result = window.callAPI("removed seat " + admission_id, true, dataString)
        return result
    },

    getWorkingOrder() {
        const dataString = {
            action: 'orderMethods',
            method: 'av_getWorkingOrder',
            order_id: localStorage.getItem('order_id')
        }
        const result = window.callAPI("Get working order", true, dataString)
        return result
    },

    computeAdditionalCharges(charges) {
        var total_charges = parseFloat(charges.add_charge1.standard) + parseFloat(charges.add_charge2.standard) + parseFloat(charges.add_charge3.standard) + parseFloat(charges.add_charge4.standard) + parseFloat(charges.add_charge5.standard);
        return formatter.format(total_charges);
    },

    getShippingAddress() {
        const useBilling = (jQuery('#use_billing_addr').is(':checked')) ? Order.billingAddress : Order.shipping_address
        return useBilling
    },

    skipEmptyAccount(customer) {
        console.log("customer", customer)
        const findBalance = Object.values(customer.data.Balances).filter((balance) => {
            if(balance.hasOwnProperty('customer_account_balance_id')) return balance
        })
        if(findBalance.length > 0) {
            console.log("findBal", findBalance)
            const hasBalance = parseInt(findBalance[0].amount.standard)

            console.log("balance check", hasBalance)

            if( isNaN( hasBalance ) || hasBalance < .01) {
                return false
            }
        } else {
            return false
        }
        

        return true
    },

    getAccountBalance() {
        const findBalance = Object.values(this.Customer.data.Balances).filter((balance) => {
            if(balance.hasOwnProperty('customer_account_balance_id')) return balance
        })
        const hasBalance = parseInt(findBalance[0].amount.standard)

        console.log("balance check 2", hasBalance)
        
        if( !isNaN( hasBalance ) || hasBalance > .01) {
            return findBalance[0]
        }
    },

    displayAccountBalance() {
        const findBalance = Object.values(this.Customer.data.Balances).filter((balance) => {
            if(balance.hasOwnProperty('customer_account_balance_id')) return balance
        })
        const hasBalance = parseInt(findBalance[0].amount.standard)

        console.log("balance check 2", hasBalance)
        
        if( !isNaN( hasBalance ) || hasBalance > .01) {
            return findBalance[0].amount.display
        }
    },

    updateSeatPriceType(performance_id, seat, pricetype) {
        const data = {
            action: "orderMethods",
            method: "av_updateSeatPriceType",
            performance: performance_id,
            seat_id: seat,
            price_type: pricetype
        }
        // console.log("data for price change", data)
        const result = window.callAPI("Update Seat pricetype", true, data)
        return result
    },

    sumAppendedPayments() {
        if(Order.payments.length > 0) {
            const payments = Order.payments.reduce((prev, curr) => prev + curr.payamount, 0);
            return parseFloat( payments )
        } else {
            return 0
        }
    }
}

// Snuck the venue object here. Could be put under Performance.js 
// too, but doesn't need it's own file.
function Venue(venue) {
    // this.id = venue.venue_id.standard
    this.name = venue.venue_description.standard
    this.street = venue.venue_street.standard
    this.city = venue.venue_city.standard
    this.state = venue.venue_state.standard.slice(-2)
    this.zip = venue.venue_zip.standard
}

Venue.renderDetailsForSelectedSeats = function () {
    jQuery('#av_shoppingCart .selectSeats__event .venue-title').text(this.name);
    jQuery('#av_shoppingCart .selectSeats__event .venue-title').after('<p class="description">' + this.street +
        '<br>' + this.city + ', ' + this.state + ' ' + this.zip + '</p>');
}