(function($) {
    $('#checkoutPaymentForm').on('submit', function(e) {
        $(this).rules("add", 
        {
            payment_option: {
                required: true
            },
            creditCardNo: {
                required: {
                    depends: function () {
                        isNotGiftCert()
                        isNotAccount()
                        totalNotZero()
                    }
                }
            },
            creditCardDate: {
                required: {
                    depends: function () {
                        isNotGiftCert()
                        isNotAccount()
                        totalNotZero()
                    }
                }
            },
            creditCardName: {
                required: {
                    depends: function () {
                        isNotGiftCert()
                        isNotAccount()
                        totalNotZero()
                    }
                }
            },
            creditCardCCV: {
                required: {
                    depends: function () {
                        isNotGiftCert()
                        isNotAccount()
                        totalNotZero()
                    }
                }
            },
            giftCardNo: {
                required: {
                    depends: function () {
                        isNotAccount()
                        totalNotZero()
                        return isNotGiftCert() == false
                    }
                }
            },
            giftValidationCode: {
                required: {
                    depends: function () {
                        isNotAccount()
                        totalNotZero()
                        return isNotGiftCert() == false
                    }
                }
            }

        })

        e.preventDefault()
        if (Performance.performance.hasOwnProperty('termsConditions') && Performance.performance.termsConditions.display != '') {
            $('#tc_accept').val('0');
            $('#tc_modal, #page_overlay').fadeIn();
            $('#tc_modal, #page_overlay').toggleClass('active')
        } else {
            if($('#tc_accept').val() != '0') {
                Purchase.finalCheckout_v2()
            }
        }

    });    


    $(document.body).on('submit', '#setSeatPriceType', function (e) {
        e.preventDefault()
        const selectedSeat = {}
        $('#seat_modal .message').text("")
        selectedSeat.pricetype = $(this).find('input[name=pricetype_option]:checked').val()
        if (selectedSeat.pricetype) {
            selectedSeat.seat_id = $(this).find('input#seat_id').val()
            selectedSeat.seat_av_id = $(this).find('input#seat_av_id').val()
            selectedSeat.seat_obj = document.getElementById(selectedSeat.seat_id)
            selectedSeat.on_order = false
            selectedSeat.state = 16
            // console.log("selected seat", selectedSeat)
            SeatMap.selectedSeats.push(selectedSeat)
            SeatMap.renderSelectedSeatsOnList()
            window.enableStep($('button.go__step2'), SeatMap.selectedSeats)
            $('#seat_modal').fadeOut()
            $('#page_overlay').fadeOut()
            $('.seatmap-overlay').fadeOut()
        } else {
            $('#seat_modal .message').text("Please select a seat price.")
        }
    })

    $(document.body).on('submit', '#edit-contact', function (e) {
        e.preventDefault()
        const prefContact = $(this).find('input[type=radio]:checked').val()
        // console.log("changing Billing Contact", prefContact)
        Purchase.setBillingContact(Purchase.findThePreferredContact(Account.contacts, prefContact))
        $(this).closest('div.info-edit').html('')
    })

    $(document.body).on('submit', '#edit-shipping-address', function (e) {
        e.preventDefault()
        const prefAddress = $(this).find('input[type=radio]:checked').val()
        // console.log("changing Shipping address", prefAddress)
        Purchase.setPurchaseAddress('shipping', prefAddress)
        $(this).closest('div.info-edit').html('')
    })

    $(document.body).on('submit', '#edit-billing-address', function (e) {
        e.preventDefault()

        const prefAddress = $(this).find('input[type=radio]:checked').val()
        // console.log("changing Billing address", prefAddress)
        Purchase.setPurchaseAddress('billing', prefAddress)
        $(this).closest('div.info-edit').html('')
    })


    $('#guestPreCheckout').on('submit', function (e) {
        e.preventDefault()
        const form = $('#guestPreCheckout')
        if (form.valid()) {
            const savedEmail = $('#guestPreCheckout #billing_email').val()
            Customer.addNewGuestCustomer(form.serializeObject())
                .then((response) => {

                    const parsed = JSON.parse(response)
                    const parsed2 = JSON.parse(parsed)
                    // console.log("guest checkout - look at Users", parsed2)
                    const userDetails = Object.values(parsed2.data.Users).filter((user) => {
                        
                        if(user.hasOwnProperty('customer_id')) {
                            const result = { customer_id: user.customer_id.standard, contact_id: user.contact_id.standard }
                            
                            return result	
                        }
                        
                    })

                    Customer.av_getCustomerInfoForCheckout(userDetails[0].customer_id.standard).then((response) => {

                        const parsed = JSON.parse(response)
                        
                        // console.log("Guest info for checkout:", parsed)
                        Account.defaultAddress = Purchase.findTheDefaultAddress(parsed.data.Addresses)
                        Account.addresses = parsed.data.Addresses
                        Account.defaultContact = Purchase.findTheDefaultContact(parsed.data.Contacts)
                        Account.defaultEmail = Account.defaultContact.email.standard
                        Account.contacts = parsed.data.Contacts
                        Order.billingContact = Account.defaultContact
                        Order.billingEmail = Account.defaultEmail
                        Order.billingAddress = Account.defaultAddress
                        Order.shippingAddress = Account.defaultAddress
                        Order.Customer = parsed
                        
                    }).then(() => {
                        const defaultContactId = Order.Customer.data.Customer.default_contact_id.standard
                        
                        // console.log("defualtContact", defaultContactId)
                        Customer.updateGuestEmail_v2( defaultContactId, savedEmail, Order.Customer.data.Contacts[defaultContactId].first_name.standard, Order.Customer.data.Contacts[defaultContactId].last_name.standard )
                        .then((response) => {
                            const parsed = JSON.parse(response)
                            const parsed2 = JSON.parse(parsed)
                            // console.log("fixing guest email part 1 again", parsed2)

                            Account.defaultAddress = Purchase.findTheDefaultAddress(parsed2.data[''].Addresses)
                            Account.addresses = parsed2.data[''].Addresses
                            Account.defaultContact = Purchase.findTheDefaultContact(parsed2.data[''].Contacts)
                            Account.contacts = parsed2.data[''].Contacts
                            Order.billingContact = Account.defaultContact
                            Order.billingAddress = Account.defaultAddress
                            Order.shippingAddress = Account.defaultAddress
                            Account.defaultEmail = parsed2.data[''].Contacts[defaultContactId].email.standard
                            Order.billingEmail = Account.defaultEmail
                            const customerSet = { data: {
                                Addresses: parsed2.data[''].Addresses, 
                                Balances: parsed2.data[''].Balances, 
                                Contacts: parsed2.data[''].Contacts, 
                                Customer: parsed2.data[''].Customer
                            }}
                            Order.Customer = customerSet

                        })
                
                    })

                    const updateData = {
                        action: 'orderMethods',
                        method: 'av_updateWorkingAccountOrder',
                        customer_id: userDetails[0].customer_id.standard,
                        // deliverymethod_id: document.querySelector('#av_accountDeliveryOptions input[name="deliveryOption"]:checked').value,
                        billingAddress: Order.billingAddress,
                        shippingAddress: Order.shippingAddress,
                        contact_id: userDetails[0].contact_id.standard,
                        order_id: Order.order_id
                    }
                    // console.log("updateData: ", updateData)
                    Order.updateWorkingAccountOrder(updateData).then((response) => {
                        const parsed = JSON.parse(response)
                        const parsed2 = JSON.parse(parsed)
                        // console.log("updated order", parsed2)
                        Purchase.applyUpdate(parsed)

                        
                    }).then(() => {	
                        Render.renderDeliveryMethods(Order.deliveryMethods, '#av_guestDeliveryOptions')
                        $('#guestDeliveryMethod').show()
                        scrollWindow($('#guestDeliveryMethod'))
                    })

                })

            // scrollWindow($('#av_checkout'))
        }
    })

    $('#guestPreCheckout').validate({
        rules: {
            billing_first_name: "required",
            billing_last_name: "required",
            billing_address1: "required",
            billing_city: "required",
            billing_state: "required",
            billing_zip: "required",
            billing_country: {
                required: true
            },

            billing_email: {
                required: true,
                email: true
            },
        },
        messages: {
            billing_first_name: "Please enter your first name",
            billing_last_name: "Please enter your last name",

            billing_email: "Please enter a valid email address",
        }
    });

    $('#userPasswordRecovery').on('submit', function(e) {
		e.preventDefault()

		const form = $('#userPasswordRecovery')
		// console.log("unserialized:", form)
		if(form.valid()) {
			// console.log("serialized:", form.serializeObject())
			Customer.sendPasswordRecovery(form.serializeObject())
			.then((response) => {
				const parse = JSON.parse(response)
				const parse2 = JSON.parse(parse)
				console.log("userPasswordRecover", parse2)
				if(parse2.response.result.status == 0) {
					const button = [{
						id: 'confirm_recovery',
						class: 'btn btn-default load-login',
						text: 'Ok',
						href: '/login?password-recovery=true',
					}]
					modalMessageManager('#page_modal', "Email Confirmed", "Check your email for a temporary password and use that to log in.", button)
				}
			})
		}

	})

    // Creating a new customer account
    $(document.body).on('click', '#newAccountSubmit', function (e) {
		if ($('#newAccountForm').valid()) {
			Customer.addNewCustomer($('#newAccountForm').serializeObject())
				.then((response) => {
					const parsed = JSON.parse(response)
					// console.log("add customer2", parsed)
					if (parsed.hasOwnProperty('errorCode')) {
						console.log("error on new Account")
						const buttons = [{
								id: 'login_account',
								class: 'canel-create-account__close-modal',
								text: 'Log in',
								href: '',
							},
							{
								id: 'back_to_create_account',
								class: 'canel-create-account__close-modal',
								text: 'Go back',
								href: '',
							}
						]
						window.modalMessageManager('#page_modal', 'Error', 'There is already an account with this email address.', buttons)
					} else if (parsed.return[0].message == 'Customer created.') {
						// console.log("customer created")
						const buttons = [{
							id: 'closeModal',
							class: 'account-created__close-modal',
							text: 'Log in',
							href: '',
						}]
						window.modalMessageManager('#page_modal', 'Account Created', 'Your account was created. Please log in.', buttons)
					} else {
						console.log("message not found", parsed.return)
					}
				})
			// refreshTestbar()
		}
	})


    // Logged in user 
    $(document.body).on('submit', '.add-new-contact', function (e) {
        e.preventDefault()
        const form = $(this).serializeObject()

        Account.addAccountContact(form).then((response) => {
            Purchase.initAccountPrePurchaseInfo('refresh', 'contact')
        })
    })

    $(document.body).on('submit', '.add-new-address', function (e) {
        e.preventDefault()
        const form = $(this).serializeObject()
        const addressType = $(this).data('address_type')
        const thenCount = Object.values(Account.addresses).length
        Account.addAccountAddress(form)
            .then((response) => {
                // console.log('reached refresh')
                // const parsed = JSON.parse(response)
                // const body = JSON.parse(parsed.body)
                // if(body.hasOwnProperty('error') == false) {

                // }

                Purchase.initAccountPrePurchaseInfo('refresh', addressType)

                return thenCount
            })
            .then((count) => {
                // console.log('reached render')
                //   const nowCount = Object.values(Account.addresses).length
                //   console.log("address Count", nowCount, count)
                //   if(nowCount > count) {
                //     Account.renderChangeAddressForm(address_type)
                //   }

                $(this).closest(".add-new-address").remove()
            })
            .catch((error) => {
                console.log(error)
            })
    })
}(jQuery))