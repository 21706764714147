const Bundles = {

    bundles: {},

    getReserveBundles() {
        const result = window.callAPI( "Reserve Bundles", true, { action: "av_getContent", method: "av_getBundles", bundleType: "reserve"})
        return result
    },

    getFlexBundles() {
        const result = window.callAPI( "Flex Bundles", true, { action: "av_getContent", method: "av_getBundles", bundleType: "flex"})
        return result
    },

    getBundlePricetypes(bundle_id = '') {
        const result = window.callAPI( bundle_id , true, { action: "av_getContent", method: "av_getBundlePriceTypes", bundleID: bundle_id })
        return result
    },

    getBundleInfo(bundle_id) {
        const result = window.callAPI('bundle info:', true, { action: "av_getContent", method: 'av_getBundleInfo', bundleID: bundle_id})
        return result
    },

    renderFlexBundles(bundles) {
        const results = Object.values(bundles).map((bundle) => {
            if(bundle !== '16') {
                return (
                    '<li id="bundle_' + bundle.id.standard + 
                    '" data-bundle_id="' + bundle.id.standard + 
                    '"><span class="short_description">' + bundle.short_description.display + 
                    '</span> <span class="description">' + bundle.description.display + 
                    '</span> <button data-bundle_id="' + bundle.id.standard + 
                    '" class="buyBundle flex">Next</button>' +
                    '<ul class="bundleSeats"></ul></li>'
                )
            }
        })
        jQuery('#bundlesOnSale').html(results);
        jQuery('.bundleContent').removeClass('reserveSeats').addClass('flexSeats');
        jQuery('.bundleContent .title').html('<h3>Available Flex Seat Subscriptions</h3>');
        jQuery('.filters, .userCheckoutForm, #guestPaymentForm, .order-summary').hide();
    },

    renderReserveBundles(bundles) {
        const results = Object.values(bundles).map((bundle) => {
            if(bundle !== '16') {
                if(bundle.name.standard != "AV_Test_Exchange" && bundle.name.standard != undefined) {
                    return (
                        '<li id="bundle_' + bundle.id.standard + 
                        '" data-bundle_id="' + bundle.id.standard + 
                        '"><span class="short_description">' + bundle.short_description.display + 
                        '</span> <button data-bundle_id="' + bundle.id.standard + 
                        '" class="buyBundle reserve">Next</button>' +
                        '<ul class="bundleSeats"></ul></li>'
                    )            
                }
            }
        })
        jQuery('#bundlesOnSale').html(results);
        jQuery('.bundleContent').removeClass('flexSeats').addClass('reserveSeats');
        jQuery('.bundleContent .title').html('<h3>Available Reserve Seat Subscriptions</h3>');
        jQuery('.filters').show();
    },

    // only rerenders the search results
    renderFilteredBundles(bundles) {
        const results = Object.values(bundles).map((bundle) => {
            if(bundle !== '16') {
                if(bundle.name.standard != "AV_Test_Exchange" && bundle.name.standard != undefined) {
                    return (
                        '<li id="bundle_' + bundle.id.standard + 
                        '" data-bundle_id="' + bundle.id.standard + 
                        '"><span class="short_description">' + bundle.short_description.display + 
                        '</span> <button class="buyBundle">Next</button>' +
                        '<ul class="bundleSeats"></ul></li>'
                    )            
                }
            }
        })
        if (results != '') {
            jQuery('#bundlesOnSale').html(results);
        } else {
            jQuery('#bundlesOnSale')
            .html('<p class="filterError" >Sorry. There are no subscriptions available matching your criteria.</p>');
        }
    },


    clearBundleOptions() {
        jQuery('.bundleSeats').html('');
    }, 

    renderBundlePriceTypes(priceTypes, bundle_id, options) {
        

        Bundles.clearBundleOptions()

        const results = Object.values(priceTypes).map((bundle) => {
            if(bundle !== '16') {
                return (
                    '<li><label for="pricetype_' + bundle.price_type_id.standard + 
                    '">' + bundle.name.display + '</label><select id="pricetype_' + bundle.price_type_id.standard + 
                    '" data-pricetype_id="' + bundle.price_type_id.standard + '" >' + FormBuilder.insertOptions(options.low, options.high) + '</select>'
                )
            }
        })
        const final = results.concat('<div class="bundleOptions"><button class="closeBundleSeats">Cancel</button> ' + 
                    ' <button class="bundlePurchase" data-bundle_id="'+ bundle_id +'" >Buy Seats</button></div></li>')

        jQuery('#bundle_' + bundle_id + '> .bundleSeats').html( final );
    }
}

const BundleFilters = {

    filterData(data, query) {
        const filteredData = data.filter( (item) => {
          if( query.data1.length > 0 && query.data1[0] != '' && item.data1 != undefined) {
            if( !query.data1.includes( item.data1.standard ) ) {
              return false;
            }
          }
          if( query.data2.length > 0 && query.data2[0] != '' && item.data2 != undefined) {
            if ( query.data2 != item.data2.standard) {
              return false;
            }
          }
          if( query.data3.length > 0 && query.data3[0] != '' && item.data3 != undefined) {
            if ( query.data3 != item.data3.standard) {
              return false;
            }
          }
          return true;
        });
        return filteredData;
    }
}

const FormBuilder = {

    insertOptions(low, high) {
        // we're going to need the same dropdown multiple times.
        let options = '';
        for (let i=low; i<=high; i++) {
            options += '<option value="' + i + '">' + i + '</option>';
        }
        const dropdown = '<option value="0">--</option><option value="" disabled>&nbsp;</option>' + options ;
        return dropdown;
    }
}