Performance = {
    id: '',
    name: '',
    description: '',
    startDate: '',


    renderDetailsForSelectedSeats() {
        jQuery('#av_shoppingCart .selectSeats__event .event-title').text(this.name);
        jQuery('#av_shoppingCart .selectSeats__event .event-title')
            .after('<p class="description">' + this.name + '<br>' + this.startDate + '</p>');
    },

    showPerformanceModal(title, message, buttonText) {
        jQuery('#performance_modal').find('.title').text(title)
        jQuery('#performance_modal').find('.message').text(message)
        jQuery('#performance_modal').find('.performance-modal__close').text(buttonText)

        jQuery('#performance_modal, #page_overlay').toggleClass('active')
        jQuery('#performance_modal, #page_overlay').fadeIn()
    },

    loadTCModal(title, message, buttonText) {
        jQuery('#tc_modal').find('.heading').html(title)
        jQuery('#tc_modal').find('.message').html(message)
        jQuery('#tc_modal').find('.termsconditions-modal__close').text(buttonText)
    },

    reloadPurchasePage(promoCode) {
        this.getEverythingPerformancePromo(promoCode).then((response) => {
                const parsed = JSON.parse(response)

                if (parsed.hasOwnProperty('exception')) {
                    jQuery('.promo-codes').append('<p class="message">The entered code is invalid.</p>')
                } else {

                    console.log("Init: ", parsed)
                    window.pricing = parsed.data[''].pricing
                    window.priceTypes = parsed.data[''].pricetypes
                    SeatMap.legends = parsed.data[''].legends
                    SeatMap.renderSeatsOnMap(parsed.data[''].admissions)
                    SeatMap.renderSeatMapLegend(SeatMap.legends)
                    jQuery('.promo-codes').append('<p class="message">' + parsed.return[0].message + '</p>')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    },

    reloadBestAvailable(promoCode = '') {
        this.getEverythingPerformanceBestAvailPromo(promoCode).then((response) => {
            const parsed = JSON.parse(response)
            console.log("promo code response:", parsed)
            if (parsed.hasOwnProperty('exception')) {
                jQuery('.promo-codes').append('<p class="message">The entered code is invalid.</p>')
            } else {
                console.log("Init: bestAvail", parsed)
                window.pricing = parsed.data[''].pricing
                window.priceTypes = parsed.data[''].pricetypes
                Render.renderBestAvailable(parsed.data[''].pricetypes)
                jQuery('.promo-codes').append('<p class="message">' + parsed.return[0].message + '</p>')
            }
        })
    },

    getEverythingPerformance() {
        return window.callAPI('Get All performance Info', true, {
            action: 'av_getContent',
            method: 'av_getPerformanceInfo',
            performanceID: window.performance_id,
            seating_type: window.seating_type
        })
    },

    getEverythingPerformance2() {
        return window.callAPI('Get All performance Info', true, {
            action: 'av_getContent',
            method: 'av_getPerformanceInfo2',
            performanceID: window.performance_id,
            seating_type: window.seating_type
        })
    },

    getEverythingPerformanceBestAvail() {
        return window.callAPI('Get Best Available Performance Info', true, {
            action: 'av_getContent',
            method: 'av_getPerformanceInfo',
            performanceID: window.performance_id,
            seating_type: window.seating_type
        })
    },
    getEverythingPerformanceBestAvailPromo(promoCode) {
        return window.callAPI('Get All Performance promo Info', true, {
            action: 'av_getContent',
            method: 'av_getPerformanceInfo',
            performanceID: window.performance_id,
            promo_code: promoCode,
            seating_type: window.seating_type
        })
    },
    getEverythingPerformancePromo(promoCode) {
        return window.callAPI('Performance w/promo Info', true, {
            action: 'av_getContent',
            method: 'av_getPerformanceInfo',
            performanceID: window.performance_id,
            promo_code: promoCode,
            seating_type: window.seating_type
        })
    },

    getThePromocode( pricetype_id ) {
        const thing = Object.values(window.pricing).find((pricetype) => {
            console.log('price hunt promo', pricetype)
            if(pricetype.hasOwnProperty('price_type_id') && pricetype.price_type_id.standard === pricetype_id) {
                console.log('want this', pricetype.promocodes.standard[0])
                return pricetype
            }
        })
        return thing.promocodes.standard[0]
    }
}