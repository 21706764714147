const AlertModal = {
    
    callSeatAlertModal(seat) {
        const message = seat.data('message')
        console.log("received message: ", message)
        if(message !== '' && seat.hasClass('active')) {
            this.launchSeatAlertModal(message)
        }
    },

    launchSeatAlertModal(message) {
        jQuery('.seat-alert .message').text(message)
        jQuery('.seat-alert').addClass('active').fadeIn()
    }
}

const callAPI = (id='', log = false, dataString = {}) => {
    const logPrefix = 'Response ' + id + ': '
    return new Promise((resolve, reject) => {
        jQuery.ajax({
            url: ct_localize.ajaxuri,
            type: 'POST',
            data: dataString,
            success: (response) => {
                // const parsed = JSON.parse(response)
                // console.log('callAPI parsed', parsed)
                // const parsed2 = ( typeof parsed === 'object' ) ? parsed : JSON.parse(parsed)
                // if (log) console.log(logPrefix, parsed2)
                resolve(response)
            },
            error: (error) => {
                reject(error)
            }
        })
    })
}

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}