const Purchase = {

    // loading the page for selling tickets on events with a seat map for choosing seats
    initializePurchasePage() {

        // window.callAPI('set default authenticate', false, {action: 'av_defaultAuthenticate'})
        
        // We have the data to build the page, go get the Performance info
        Performance.getEverythingPerformance()
        .then((response) => {
            const parsed = JSON.parse(response)
            
            // console.log("Init: ", parsed)
            return parsed
        })
        .then((parsed) => {
            if (parsed.hasOwnProperty('exception')) {
                jQuery('#cursor_box').remove()
                
                const buttons = [{
                    id: 'return_season_tickets',
                    class: 'btn-default',
                    text: 'Back to Seasons & Tickets'
                }, {
                    id: 'return_previous',
                    class: 'btn-error',
                    text: 'Return to previous page'
                }]
                window.modalMessageManager('#page_modal', 'Event Error', parsed.exception.message, buttons)
            } else {
                // Storing these globally made sense at one point...
                window.pricing = parsed.data[''].pricing
                window.priceTypes = parsed.data[''].pricetypes

                // We need to track when we change sessions per the av API. Lose the session lose your order.
                localStorage.setItem('av_session', parsed.session)

                // The seats
                SeatMap.seats = parsed.data[''].admissions

                // The seat legend
                SeatMap.legends = parsed.data[''].legends

                // for now, we're gonna just store everything from the performance.
                Performance.performance = parsed.data[''].performances[window.performance_id.toUpperCase()]
            }


            // If page reloads or user starts an order, leaves/returns before session expires, we continue the order
            if ( localStorage.getItem('order_id') != null && localStorage.getItem('order_id')) {
                console.log("there's an order id", localStorage.getItem('order_id'))
                if (localStorage.getItem('order_session') == localStorage.getItem('av_session') && localStorage.getItem('order_session') != null) {
                    // Get the Order object from av
                    Order.getWorkingOrder()
                    .then((response) => {
                        console.log("continuing working order ", localStorage.getItem('order_id'), JSON.parse(response))
                        const parsed = JSON.parse(response)
                        const parsed2 = JSON.parse(parsed)
                        // this basically stores all the order data
                        Purchase.startWorkingOrder(parsed2, 'continue')
                        jQuery('#av_seatsOnHold').show()

                    })
                    .then(() => {
                        // We can skip to step two, (seats are already selected)
                        window.enableStep(jQuery('button.go__step2'), true)
                        if (window.seating_type === 'mixed') {
                            SeatMap.renderSeatsOnMap(SeatMap.seats)
                            SeatMap.renderSeatMapLegend(SeatMap.legends)
                        }
                    })
                    .then(() => {
                        const buttons = [
                            {
                                id: 'reloaded-purchase_edit-seats',
                                class: 'btn btn-default',
                                text: 'Add/Edit Seats',
                                href: '#',
                                
                            },
                            {
                                id: 'reloaded-purchase_continue',
                                class: 'btn btn-default',
                                text: 'Continue With Existing Seat(s)',
                                href: '#',
                            } 
                        ]

                        modalMessageManager('#page_modal', "Continue with Order?", "Looks like you've already started an order with this Performance. How do you want to continue?", buttons)
                    })
                } else {
                    // The AV session doesn't match. User needs to start over.
                    console.log("sessions don't match 1", localStorage.getItem('order_session'), localStorage.getItem('av_session'))
                    localStorage.removeItem('order_session')
                    localStorage.removeItem('order_id')
                    const button = [{
                        id: 'reload_expired',
                        class: 'reload-page',
                        text: 'Reload',
                        href: '',
                    }]
                    modalMessageManager("#page_modal", "Error", "Your Session has expired. Please reload to start over.", button)
                    SeatMap.renderSeatsOnMap(SeatMap.seats)
                    SeatMap.renderSeatMapLegend(SeatMap.legends)
                }
            } else {
                // No order, just render the map and map legend
                // console.log("no order_id")
                SeatMap.renderSeatsOnMap(SeatMap.seats)
                SeatMap.renderSeatMapLegend(SeatMap.legends)
            }

            console.log("this is performance", Performance.performance)
            // If a popup message is set in the data, display a modal.
            if (Performance.performance.hasOwnProperty('popupmessage') && Performance.performance.popupmessage.standard != '') {
                Performance.showPerformanceModal('', Performance.performance.popupmessage.standard, "Close")
            }
            jQuery('#av_seatmap .seatmap-overlay').fadeOut()
            jQuery('#av_seatmap #cursor_box').remove()
        
            // Load the Terms&Conditions modal for checkout
            if (Performance.performance.hasOwnProperty('termsConditions') && Performance.performance.termsConditions.display != '') {
                Performance.loadTCModal('Terms &amp; Conditions', Performance.performance.termsConditions.display, "Confirm")
            }
    
        })
        .catch((error) => {
            console.log(error)
        })           
 
        // getting 'lists' which populate certain elements
        Order.getAllLists()
        .then((response) => {
                const parsed = JSON.parse(JSON.parse(response))
                console.log("getAllLists results", parsed)
                // Storing the data
                Order.cityList = parsed.response.listsSorted.cities
                Order.priceTypeLabels = parsed.response.listsSorted["Price Types"]
                Order.payMethodLabels = parsed.response.listsSorted["Payment Methods"]
                if( !Order.hasOwnProperty('payments') || Order.payments === undefined ) {
                    Order.payments = []
                }
        }) .catch((error) => {
            console.log(error)
        })

        // Gifts are how users add donations to an order. Gifts are objects in the db which we attach an amount later.
        Order.getGifts()
        .then((response) => {
            const parsed = JSON.parse(response)
            // console.log("loading gifts", parsed)

            // storing gifts
            Order.gifts = parsed.data.SearchResults
            const giftDescription = Object.values(Order.gifts).find(gift => gift.name.standard === "Court-Online Giving")
            // text from the gift object populates the donation field on checkout.
            jQuery('#giftDescription').text(giftDescription.description.standard)
        })
        .catch((error) => {
            console.log(error)
        })
        
        
    },

    applyUpdate(data) {
        const parsed = JSON.parse(data)
        // console.log("this is data", parsed)
        const paymentMethodFiltered = Object.values(parsed.data.AvailablePaymentMethods).filter((paymethod) => {
            if (paymethod.hasOwnProperty('type') && paymethod.hasOwnProperty('payment_method_id')) {
                return paymethod
            }
        })
        const paymentMethodsSorted = Object.values(paymentMethodFiltered).sort((a, b) => (a.type.standard > b.type.standard) ? 1 : ((b.type.standard > a.type.standard) ? -1 : 0))
        const accountPaymentInfo = Object.values(paymentMethodFiltered).filter((paymethod) => {
            if (paymethod.hasOwnProperty('type') && paymethod.type.standard === 'Account') {
                console.log('found account')
                return paymethod
            }
        })
        Order.paymentMethods = paymentMethodsSorted
        if( !Order.hasOwnProperty('payments') || Order.payments === undefined ) {
            Order.payments = []
        }
        Order.appendPaymentsTotal = Order.sumAppendedPayments()
        Order.deliveryMethods = parsed.data.DeliveryMethodDetails
        Customer.accountInfo = accountPaymentInfo
        console.log('update applied', parsed)
    },

    // loading the page for selling tickets with the best available method
    initialBestAvailable() {
        Order.getAllLists().then((response) => {
                const parsed = JSON.parse(JSON.parse(response))
                Order.cityList = parsed.response.listsSorted.cities
                Order.priceTypeLabels = parsed.response.listsSorted["Price Types"]
                Order.payMethodLabels = parsed.response.listsSorted["Payment Methods"]
                if( !Order.hasOwnProperty('payments') || Order.payments === undefined ) {
                    Order.payments = []
                }
                Order.getCitiesList().then((response) => {
                        Order.cityList = JSON.parse(response)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

                Order.getGifts().then((response) => {
                        const parsed = JSON.parse(response)
                        Order.gifts = parsed.data.SearchResults
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .then(() => {
                Performance.getEverythingPerformanceBestAvail().then((response) => {
                        const parsed = JSON.parse(response)
                        console.log("Init: bestAvail", parsed)
                        localStorage.setItem('av_session', parsed.session)
                        window.pricing = parsed.data[''].pricing
                        window.priceTypes = parsed.data[''].pricetypes
                        Performance.performance = parsed.data[''].performances[window.performance_id]


                        return parsed
                    })
                    .then((parsed) => {
                        if (localStorage.getItem('order_id') != undefined && localStorage.getItem('order_id') != '') {
                            if (localStorage.getItem('order_session') == localStorage.getItem('av_session')) {
                                Order.getWorkingOrder(localStorage.getItem('order_id'))
                                    .then((response) => {
                                        const parsed = JSON.parse(response)
                                        console.log("start working order called", JSON.parse(parsed))
                                        Purchase.startWorkingOrder(JSON.parse(parsed), 'continue')
                                        jQuery('#av_seatsOnHold').show()
                                        Render.renderBestAvailable(window.pricetypes)
                                    })
                                    .then(() => {
                                        window.enableStep(jQuery('button.go__step2'), true)
                                    })
                            } else {
                                console.log("sessions don't match", localStorage.getItem('order_session'), localStorage.getItem('av_session'))
                                // this.orderIsEmpty("Your session has timed out. Your cart is empty.")
                            }
                        }
                        return parsed
                    })
                    .then((parsed) => {
                        jQuery('#av_bestAvail #cursor_box').remove()
                        Render.renderBestAvailable(parsed.data[''].pricetypes)
                    })
                    .then(() => {

                        // console.log("this is performance", Performance.performance)
                        if (Performance.performance.hasOwnProperty('popupmessage') && Performance.performance.popupmessage.standard != '') {
                            Performance.showPerformanceModal('', Performance.performance.popupmessage.standard, "Close")
                        }
                    })
                    .then(() => {
                        if (Performance.performance.hasOwnProperty('termsConditions') && Performance.performance.termsConditions.display != '') {
                            Performance.loadTCModal('Terms &amp; Conditions', Performance.performance.termsConditions.display, "Confirm")
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    },

    getAllLists() {
        Order.getAllLists().then((response) => {
                const parsed = JSON.parse(response)
                Order.cityList = parsed.response.listsSorted.cities
                Order.priceTypeLabels = parsed.response.listsSorted["Price Types"]
                Order.payMethodLabels = parsed.response.listsSorted["Payment Methods"]
            })
            .catch((error) => {
                console.log(error)
            })
    },

    initTicketPurchaseSeatMap() {

        const selections = SeatMap.selectedSeats.filter((seat) => {
            if(seat.hasOwnProperty('on_order') && !seat.on_order) {
                return seat
            }
        })
        const seats = this.formatSeatsForHold(selections)
        const dataString = {
            action: 'orderMethods',
            method: 'av_setReserveSeats',
            performanceID: window.performance_id,
            selectedSeats: [seats]
        }
        // console.log("Init datastring:", dataString)
        const results = window.callAPI('initTicketPurchaseSeatMap Response: ', false, dataString)
        return results;
    },

    initTicketPurchaseBestAvailable() {
        jQuery('.seatmap-overlay').fadeIn()
        const selections = []
        jQuery('.price-option__seats').each(function () {
            const prop = jQuery(this).data('id')
            const val = jQuery(this).val()
            const promo = (jQuery(this).data('promo_id')) ? jQuery(this).data('promo_id') : null
            selections.push({
                price_id: prop,
                amount: val,
                promocode: promo
            })
        })

        const seats = this.formatSelectionsForBestAvail(window.performance_id, selections)
        const dataString = {
            action: 'orderMethods',
            method: 'av_setBestAvailable',
            seats
        }
        const results = window.callAPI('Initialize Order Response: ', false, dataString)

        return results;
    },

    initBundleBestAvailable( bundle, performances, seatData ) {
        const data = {
            action: 'orderMethods',
            method: 'av_setBundles',
            bundle_id: bundle,
            performance_ids: performances,
            seatData
        }
        console.log("sending to av", data)

        const results = window.callAPI('Initialize Bundles: ', false, data)

        return results;
        
    },

    formatSelectionsForBestAvail(performance, selections) {
        const filtered = selections.filter((selection) => {
            return selection.amount > 0
        })
        const formatted = filtered.map((selection) => {
            const priceid = (selection.promocode) ? `${ selection.price_id }::${ selection.promocode }` : `${ selection.price_id }`
            if (selection.amount > 0) {
                return (
                    `{"method": "getBestAvailable",
            "params": {
                "reqNum::${ priceid }" : "${ selection.amount }",
                "perfVector": "${ performance }",
                "optNum": "1" 
            }
        }`
                )
            }
        })
        return formatted.join(',')
    },

    // seats get sent with their pricetype
    formatSeatsForHold($seats) {

        const selections = $seats.map((seat) => {
            return (
                [
                    seat.pricetype,
                    seat.seat_av_id
                ]
            )
        });
        return selections;
    },
    
    
    // 'type' = 'init' or 'continue' av data format is different for each

    startWorkingOrder(orderOnHold, type) {
        // console.log("heres the data ", orderOnHold.data[''])
        const c = orderOnHold.data['']
        // console.log("Order on hold: ", c)

        const paymentMethodFiltered = Object.values(c.AvailablePaymentMethods).filter((paymethod) => {
            if (paymethod.hasOwnProperty('type') && paymethod.hasOwnProperty('payment_method_id')) {
                return paymethod
            }
        })
        // console.log('should be expiration', Order.setOrderExpiration(c.instance_time.display[0]), c.instance_time.display[0])
        const now = new Date().getTime()
        
        const paymentMethodsSorted = Object.values(paymentMethodFiltered).sort((a, b) => (a.type.standard > b.type.standard) ? 1 : ((b.type.standard > a.type.standard) ? -1 : 0))

        Order.setOnHoldTimer(Order.setOrderExpiration(c.instance_time.display[0]))
        Order.currentDeliveryMethod = c.Order.deliverymethod_id.standard
        Order.performanceDetails = c.PerformanceDetails
        Order.deliveryMethods = c.DeliveryMethodDetails
        Order.paymentMethods = paymentMethodsSorted
        //if(!Order.hasOwnProperty('payments') || Order.payments != undefined)
        // Order.payments = c.payments
        // Order.transactions = c.paymentTransactions
        Order.performanceRollup = c.PerformanceRollup
        Order.order_id = c.Order.order_id.standard
        Order.admissions = c.Admissions
        Order.orderDetails = c.Order
        const checkPerformance = Object.keys(Order.performanceRollup).filter(performance => performance.id == window.performance_id)
        if (checkPerformance) {
            Render.renderSeatsOnOrder(c.Admissions, c.PerformanceDetails, c.PerformanceRollup)
        }
        if (type == 'init') {
            localStorage.setItem('order_id', c.Order.order_id.standard)
            localStorage.setItem('order_session', orderOnHold.session)
        }
        // check to see if performance is already in list of Performances



        // Ugh. just for now.
        // Order.theWholeThing = c

    },

    // updateWorkingOrder(orderOnHold) {

    //     const c = orderOnHold.data['']
    //     console.log("UPDATED order on hold: ", c)
    //     Order.currentDeliveryMethod = c.Order.deliverymethod_id.standard
    //     Order.deliveryMethods = c.DeliveryMethodDetails
    //     Order.performanceDetails = c.PerformanceDetails
    //     Order.paymentMethods = c.AvailablePaymentMethods
    //     Order.performanceRollup = c.PerformanceRollup
    //     Order.order_id = c.Order.order_id.standard
    //     Order.admissions = c.Admissions
    //     Order.orderDetails = c.Order

    //     // Performance.performances.push(c.PerformanceDetails)

    //     // Ugh. just for now.
    //     // Order.theWholeThing = c
    // },

    updateWorkingOrderGift(orderOnHold) {

        const c = orderOnHold.data['']
        console.log("UPDATED order with gift: ", c)
        Order.currentDeliveryMethod = c.Order.deliverymethod_id.standard
        // Order.deliveryMethods = c.DeliveryMethodDetails
        Order.performanceDetails = c.PerformanceDetails
        // Order.paymentMethods = c.AvailablePaymentMethods
        Order.payements = c.payments
        Order.transactions = c.payementTransactions
        Order.performanceRollup = c.PerformanceRollup
        Order.order_id = c.Order.order_id.standard
        Order.admissions = c.Admissions
        Order.giftsOnOrder = c.Gifts
        Order.orderDetails = c.Order

        // Performance.performances.push(c.PerformanceDetails)

        // Ugh. just for now.
        //Order.theWholeThing = c
    },

    findTheDefaultAddress(addresses) {
        const defaultAddress = Object.values(addresses).filter((address) => {
            if (address.hasOwnProperty('default')) {
                if (address.default.standard == '1') {
                    return address
                }
            }
        })
        return defaultAddress[0]
    },

    findTheDefaultContact(contacts) {
        const defaultContact = Object.values(contacts).filter((contact) => {
            if (contact.hasOwnProperty('default')) {
                if (contact.default.standard == '1') {
                    return contact
                }
            }
        })
        return defaultContact[0]

    },

    findThePreferredContact(contacts, id) {
        const defaultContact = Object.values(contacts).filter((contact) => {
            if (contact.hasOwnProperty('default')) {
                if (contact.contact_id.standard == id) {
                    return contact
                }
            }
        })
        return defaultContact[0]

    },

    setPurchaseAddress(type, id) {
        if (type === 'billing') {
            Order.billingAddress = Account.addresses[id]
        } else if (type === 'shipping') {
            Order.shippingAddress = Account.addresses[id]
        }
        Render.renderLoggedInPreCheckoutInfo()
    },

    setBillingContact(contact) {
        console.log("set new billing contact", contact)
        Order.billingContact = contact
        Order.billingEmail = contact.email.standard
        Render.renderLoggedInPreCheckoutInfo()
    },

    initAccountPrePurchaseInfo(initType, address_type = '', callback) { // initType == 'init' or 'refresh' only
        
        Customer.av_getCustomerInfoForCheckout(localStorage.getItem('av_user'))
            .then((response) => {

                const parsed = JSON.parse(response)

                console.log("Info for checkout:", parsed)
                Account.defaultAddress = this.findTheDefaultAddress(parsed.data.Addresses)
                Account.addresses = parsed.data.Addresses
                Account.defaultContact = this.findTheDefaultContact(parsed.data.Contacts)
                Account.defaultEmail = Account.defaultContact.email.standard
                Account.contacts = parsed.data.Contacts
                Order.billingContact = Account.defaultContact
                Order.billingEmail = Account.defaultEmail
                Order.billingAddress = Account.defaultAddress
                Order.shippingAddress = Account.defaultAddress
                Order.Customer = parsed

                
            })
            .then((response) => {
                // console.log("this is customer: ", response)
                if (initType === 'init') {
                    Render.renderLoggedInPreCheckoutInfo()
                } else if (initType == 'reset' && address_type == 'contact') {
                    setTimeout(function () {
                        Account.renderChangeContactForm(address_type)
                    }, 1500)
                } else if (initType == 'reset' && address_type != '') {
                    setTimeout(function () {
                        Account.renderChangeAddressForm(address_type)
                    }, 1500)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        if(callback) {
            callback()
        }
    },

    orderIsEmpty(message) {
        localStorage.removeItem('order_id')
        localStorage.removeItem('order_session')
        console.log("start this order over")

        // alert(message)
        // location.reload()
    },

    prepurchaseChangeInfo(infoType) {
        if (infoType === 'billing-address') {
            Account.renderChangeAddressForm('billing')
        } else if (infoType === 'contact') {
            Account.renderChangeContactForm()
        } else if (infoType === 'shipping-address') {
            Account.renderChangeAddressForm('shipping')
        }
    },

    addNewAddress(form) {
        const data = {
            street: form.find('address1') + ' ' + form.find('address2'),
            city: form.find('city'),
            state: form.find('state'),
            zip: form.find('zip'),
            country: form.find('country')
        }
        const results = window.callAPI('Add new address', true, {
            action: 'av_addNewAddress',
            address: data
        })
    },

    addGiftToOrder(amount) {
        const theGift = Object.values(Order.gifts).filter((gift) => {
            if (gift.hasOwnProperty('name')) {
                return gift.name.standard == "Court-Online Giving"
            }
        })
        console.log("theGift", theGift)
        const data = {
            action: 'orderMethods',
            method: 'av_addGiftToOrder',
            gift_id: theGift[0].id.standard,
            gift_amount: amount
        }

        const results = window.callAPI('Add gift to order', true, data)
        return results
    },

    prePopAccountPayment(balance, total) {
        // get account balance
        const balInt = parseFloat(balance)
        console.log("prePop", balance, total)
        // if account balance 0, exit and Return $0.00
        if (balInt  < .01) {
            return formatter.format(0)
        }
        
        // get purchase total
        const totInt = parseFloat( total )

        // if account balance > purchase total, populate with purchase total
        if ( balInt >= totInt) {
            return formatter.format( totInt )
        } else if( balInt < totInt ) {
            // else populate with balance total
            return formatter.format( balInt )
        } else {
            // this shouldn't happen
            return 'Sorry. There was an error.'
        }
        
    },


    appendPartialPayment(paymethod, amount, flagged) {
        const paymethodExists = Order.payments.find((payment) => payment.method === paymethod )
        if(!paymethodExists) {
            Order.payments.push({ method: paymethod, payamount: amount })
            Order.appendPaymentsTotal = Order.sumAppendedPayments()
            Render.renderFinalCheckout('account')
        } else {
            // popup to confirm we are going to replace the payment (don't want same paymethod twice)

            const item = Order.payments.map((e) => { return e.method }).indexOf(paymethod)
            Order.payments[item].payamount = amount
            Order.appendPaymentsTotal = Order.sumAppendedPayments()
            Render.renderFinalCheckout('account')
        }
    },

    showSecondaryPayment() {
        const tot = parseFloat(Order.orderDetails.effective_due.standard)
        const totPayments = Order.appendPaymentsTotal
        if(totPayments < tot) {
            const diff = tot - totPayments
            return { total: tot, paid: totPayments, difference: diff}
        }

        return false
    },

    removePartialPayment(method_id) {
        Order.payments = Order.payments.filter((payment) => {
            return payment.method !== method_id
        })
        Order.appendPaymentsTotal = Order.sumAppendedPayments()
        Render.renderFinalCheckout('account')
    },

    removePaymentFromOrder(payment_on_order_id) {

    },

    addGiftCardPayment(pay_method, card_no, validation_no) {
        const data = {
            action: 'orderMethods',
            method: 'av_addGiftCardPayment',
            paymethod: pay_method,
            card: card_no,
            validation: validation_no
        }

        const results = window.callAPI('add gift certificate:', true, data)
        return results

    },

    removeDonation() {

        const theGift = Object.values(Order.giftsOnOrder).filter((gift) => {
            if(gift.hasOwnProperty('ordergift_id') && gift.pledge_amount.standard != '0.00') {
                return gift
            }
        })

        console.log("theGift to remove", theGift)

        const data = {
            action: 'orderMethods',
            method: 'av_removeDonation',
            // gift_id: theGift[0].id.standard
            gift_id: theGift[0].ordergift_id.standard
        }

        const results = window.callAPI('Remove gift', false, data)
        return results
    },

    changeAddressOnOrder(form) {

    },

    validSeatsOnOrder(admissions) {
        const validSeats = Object.values(admissions).filter((admission) => {
            if (admission.state == 24) {
                return admission
            }
        })
        return validSeats.length
    },

    insertCitySelect(formId, formName) {
        // console.log(Order.cityList)
        const list = Order.cityList.map((city) => {
            return (
                `<option value="${city.key}">${city.key}</option>`
            )
        })
        return `<select class="city-list" name="${formName}" id="${formId}" style="width: 75%">
                    ${ list.join(' ') }
                </select>`
    },

    isPerformanceInRollup() {
        const isRollup = Object.keys(Order.performanceRollup).filter(performance => performance.id === window.performance_id)
        if (isRollup) {
            return true
        }
        return false
    },

    calculateTotalTickets(admissions) {
        const countable = Object.values(admissions).filter((admission) => {
            if(admission.state === '24') {
                return admission
            }
        })
        return countable.length
    },

    calculateTotalDue(cost, payment) {
        console.log(cost, payment)
        const result = parseFloat( cost ) - parseFloat( payment )
        if( result >= 0 ) {
            return result
        } else {
            return 'Error'
        }
    },

    cardCheckout() {
        const expiry = jQuery('#creditCardDate').val()

        const data = {
            action: 'orderMethods',
            method: 'av_cc_submitCheckout',
            deliveryMethod: jQuery('input[name=deliveryOption]:checked').val(),
            cardNumber: jQuery('#creditCardNo').val(),
            expires: expiry.replace(/\D/g, ''),
            cardCCV: jQuery('#creditCardCCV').val(),
            cardholderName: jQuery('#creditCardName').val(),
            total: jQuery('.orderSummary .totalDue').text(),
            paymentMethod: jQuery('input[name=payment_option]:checked').val(),
            orderID: Order.order_id
        }

        // console.log("checkout data: ", data);
        jQuery.post(ct_localize.ajaxuri,
            data,
            (response) => {
                const parsed = JSON.parse(response)
                // console.log(parsed);
                if (parsed.message == "Order created." && parsed.data.Order.order_number.standard != '') {
                    localStorage.removeItem('order_id')
                    localStorage.removeItem('order_session')
                    Order.orderCompleted(parsed)
                }
            }
        );
    },

    finalCheckout_v2() {
        let data = {}
        //need to iterate through payments that are appended to the order
        
        const totalDue = parseFloat(Order.orderDetails.grand_total.standard)
        if (Order.payments.length > 0 && Order.appendPaymentsTotal < totalDue) {
            data = {
                action: 'orderMethods',
                method: 'av_multiPay_submitCheckout',
                multpayment: true,
                deliveryMethod: jQuery('input[name=deliveryOption]:checked').val(),
                // payments: Order.payments.map((payment) => { return { paymethod: payment.method, amount: payment.payamount } })
                firstPayMethod: Order.payments[0].method,
                firstPayAmount: Order.payments[0].payamount,
                firstPayDescription: jQuery('input[name=payment_option]:checked').data('name')
            }

            // Secondary Payment
            const secPayTypeName = jQuery('input[name=sec_payment_option]:checked').data('type')
            
            if(secPayTypeName === 'GiftCertificate') {
               
                data.secPayMethod = jQuery('input[name=payment_option]:checked').val()
                data.secGiftCardNo = jQuery('#secGiftCardNo').val()
                data.secGiftValidationCode = jQuery('#secGiftValidationCode').val()
                data.secPaymentDescription = jQuery('input[name=sec_payment_option]:checked').data('name')


            } else if(secPayTypeName === 'CreditCard') {

                const expiry = jQuery('#secCreditCardDate').val()
                data.secPayMethod = jQuery('input[name=sec_payment_option]:checked').val()
                data.secPaymentDescription = jQuery('input[name=sec_payment_option]:checked').data('name')
                data.secCardNumber = jQuery('#secCreditCardNo').val()
                data.secExpires = expiry.replace(/\D/g, '')
                data.secCardCCV = jQuery('#secCreditCardCCV').val()
                data.secCardholderName = jQuery('#secCreditCardName').val()
            }
            data.secPayName = secPayTypeName
            data.total = jQuery('.orderSummary .totalDue').text()
            data.orderID = Order.order_id
            data.customer = Order.Customer
            data.performances = Order.performanceDetails

        } else {
            const payTypeName = jQuery('input[name=payment_option]:checked').data('type')
        
            if(payTypeName === 'GiftCertificate') {
                data = {
                    action: 'orderMethods',
                    method: 'av_giftCard_submitCheckout',
                    deliveryMethod: jQuery('input[name=deliveryOption]:checked').val(),
                    paymentMethod: jQuery('input[name=payment_option]:checked').val(),
                    giftCardNo: jQuery('#giftCardNo').val(),
                    giftValidationCode: jQuery('#giftValidationCode').val(),
                    paymentDescription: jQuery('input[name=payment_option]:checked').data('name'),
                    orderID: Order.order_id,
                    customer: Order.Customer,
                    performances: Order.performanceDetails
                }

            } else if(payTypeName === 'CreditCard') {

                const expiry = jQuery('#creditCardDate').val()

                data = {
                    action: 'orderMethods',
                    method: 'av_cc_submitCheckout',
                    deliveryMethod: jQuery('input[name=deliveryOption]:checked').val(),
                    cardNumber: jQuery('#creditCardNo').val(),
                    expires: expiry.replace(/\D/g, ''),
                    cardCCV: jQuery('#creditCardCCV').val(),
                    cardholderName: jQuery('#creditCardName').val(),
                    total: Order.orderDetails.grand_total.standard,
                    paymentMethod: jQuery('input[name=payment_option]:checked').val(),
                    paymentDescription: jQuery('input[name=payment_option]:checked').data('name'),
                    orderID: Order.order_id,
                    customer: Order.Customer,
                    performances: Order.performanceDetails
                }
            } else if(payTypeName === 'Account') {
                data = {
                    action: 'orderMethods',
                    method: 'av_addAccountPayment',
                    paymethod: Order.payments[0].method,
                    payAmount: Order.payments[0].payamount,
                    orderID: Order.order_id,
                    customer: Order.Customer,
                    performances: Order.performanceDetails
                }
            }
            data.multpayment = false
        }

        // console.log("checkout data: ", data);
        if(data) {
            jQuery.post(ct_localize.ajaxuri,
                data,
                (response) => {
                    const parsed = JSON.parse(response)
                    const parsed2 = JSON.parse(parsed)
                    console.log("checkout response", parsed2)

                    if(parsed2.confirmation == true) {
                        localStorage.removeItem('order_id')
                        const url = window.location.href + parsed2.url + '/'
                        window.location.href = url
                    }
                }
            )
        }

    },

    finalCheckout() {
        // console.log("final checkout called...")


        const payTypeName = jQuery('input[name=payment_option]:checked').data('type')
        let data = {}
        if(payTypeName === 'GiftCertificate') {
            data = {
                action: 'orderMethods',
                method: 'av_giftCard_submitCheckout',
                deliveryMethod: jQuery('input[name=deliveryOption]:checked').val(),
                paymentMethod: jQuery('input[name=payment_option]:checked').val(),
                giftCardNo: jQuery('#giftCardNo').val(),
                giftValidationCode: jQuery('#giftValidationCode').val(),
                paymentDescription: jQuery('input[name=payment_option]:checked').data('name'),
                orderID: Order.order_id,
                customer: Order.Customer,
                performances: Order.performanceDetails
            }

        } else if(payTypeName === 'CreditCard') {

            const expiry = jQuery('#creditCardDate').val()

            data = {
                action: 'orderMethods',
                method: 'av_cc_submitCheckout',
                deliveryMethod: jQuery('input[name=deliveryOption]:checked').val(),
                cardNumber: jQuery('#creditCardNo').val(),
                expires: expiry.replace(/\D/g, ''),
                cardCCV: jQuery('#creditCardCCV').val(),
                cardholderName: jQuery('#creditCardName').val(),
                total: jQuery('.orderSummary .totalDue').text(),
                paymentMethod: jQuery('input[name=payment_option]:checked').val(),
                paymentDescription: jQuery('input[name=payment_option]:checked').data('name'),
                orderID: Order.order_id,
                customer: Order.Customer,
                performances: Order.performanceDetails
            } 
        }
        // console.log("checkout data: ", data);
        if(data) {
            jQuery.post(ct_localize.ajaxuri,
                data,
                (response) => {
                    const parsed = JSON.parse(response)
                    const parsed2 = JSON.parse(parsed)
                    // console.log("checkout response", parsed2)

                    if(parsed2.confirmation == true) {
                        localStorage.removeItem('order_id')
                        const url = window.location.href + parsed2.url + '/'
                        window.location.href = url
                    }
                }
            )
        }
    }
}