const SeatMap = {


    renderSeatsOnMap(input) {

        // console.log("passed to render: ", input);
        const result = Object.values(input).map((seat) => {
            if(seat.hasOwnProperty("seat_id")) {
                const seatStatus = []
                // "A" status is an available seat.
                if(seat.status.standard === 'A') {
                    seatStatus[0] = 'style="fill: ' + seat.price_colour.standard + '"'
                    seatStatus[1] = 'r="6" '
                    seatStatus[2] = ' '
                } else if (seat.status.standard === 'O') {
                    
                    if(localStorage.getItem('order_id') && Order.admissions != undefined) {
                        const isMySeat = Object.values(Order.admissions).find((admission) => {
                            if(admission.hasOwnProperty('seat_id')) {
                                console.log("comparing", admission.seat_id.standard, seat.seat_id.standard)
                                if(admission.seat_id.standard === seat.seat_id.standard) {
                                    return true
                                }
                            }
                        })
                 
                        if(isMySeat) {
                            seatStatus[0] = 'style="fill: ' + seat.price_colour.standard + '"'
                            seatStatus[1] = 'r="6" '
                            seatStatus[2] = ' active '
                        }
                        // if(seat.seat_id.standard === )
                    } else {
                        seatStatus[0] = ''
                        seatStatus[1] = 'r="4" '
                        seatStatus[2] = ' taken '
                    }
                } else if (seat.status.standard === 'H' && seat.hold_value_id.standard != '') {
                    seatStatus[0] = 'style="fill: ' + seat.price_colour.standard + '"'
                    seatStatus[1] = 'r="6" '
                    seatStatus[2] = ' '
                } else {
                    seatStatus[0] = ''
                    seatStatus[1] = 'r="4" '
                    seatStatus[2] = ' '
                }

                // const showSelected = (seat.status.standard === 'O') ? ' active ' : ''
                return(
                    '<g id="seatgroup_' + seat.seat_id.standard + '"><title>' + seat.description.standard + '</title>' +
                    '<circle data-seatID="' + seat.seat_id.standard + '" class="seat ' + seatStatus[2] + seat.status.standard + ' " ' + seatStatus[0] +
                    'id="seat_' + seat.seat_id.standard + '" cx="' + seat.x_pos.standard[0] + '" cy="' + seat.y_pos.standard[0] + '" ' + seatStatus[1] +
                    'data-desc="' + seat.description.standard + '" data-message="' + seat.message.standard + 
                    '" data-price="' + seat.price_value_id.standard + '" /></g>'
                )
            } else {
                return null
            }
        })
        
        jQuery('#courtTheatre_seatmap #seats').html(result.join(' '))
    },
    
    renderLegendPriceTypeLoop(legendItem) {
        const value_id = legendItem.value_legend_id.standard
        const legendPricesFiltered = Object.values(window.priceTypes).filter((pricetype) => {
            if(pricetype.hasOwnProperty('price_type_id')) {
                return pricetype
            }
        })

        const legendPrices = legendPricesFiltered.map((pricetype) => {

            const value = (pricetype.price_type_id.standard) ? Seat.findSeatPriceOnPriceType(window.pricing, pricetype.price_type_id.standard, value_id) : null
            if(value) {
                const labelInfo = this.renderPriceTypeLabel(pricetype.price_type_id.standard)

                return {
                    price_value: value,
                    price_type_id: pricetype.price_type_id.standard,
                    order: labelInfo[1],
                    label: labelInfo[0],
                    promo: (Seat.checkForPromocode(window.pricing, pricetype.price_type_id.standard)) ? '<span class="dashicons dashicons-tag"></span>' : ' ' 
                }
            }
            
        })
        legendPrices.sort((a,b) => (a.order > b.order) ? 1 : -1 )
        // console.log("it chokes here:", legendPrices)
        const result = legendPrices.map((pricetype) => {
            if(pricetype) {
                return (
                    `<span class="pricerow"><span class="price-name">${ pricetype.promo } ${ pricetype.label }:</span> <span class="price-value">${ pricetype.price_value }</span></span> `
                )
            }
        }).join(' ')

        return result
    },
    
    renderSeatMapLegend(input) {

        const legendItems = Object.values(input).filter((legendItem) => {
            if(legendItem.hasOwnProperty('value_legend_id') && legendItem.type.standard === 'PriceValue') {
                return legendItem
            }
        })

        const result = legendItems.map((legendItem) => {
            return (
                `<li class="legend-item"><span class="legend-indicator"><span class="seat-marker" style="background-color:${legendItem.colour.standard}"></span> ${legendItem.description.standard}</span><span class="legend-prices"> ${ this.renderLegendPriceTypeLoop(legendItem) }</span></li>`
            )
        })
        jQuery('#av_seatmap .legend-items').html(result.join(' '))
    },

    selectedSeats: [],

    renderSelectedSeatsOnList() {
        let totalCount = (this.selectedSeats.length > 0) ? this.selectedSeats.length : '';
        let totalPrice = this.selectedSeatsTotalCost()
        const seatListLabels = ['<li class="titleRow"><span class="sectionTitle listsection">Section</span> <span class="rowTitle listrow">Row</span> <span class="seatTitle listseat">Seat</span> <span class="priceType">Pricing</span></li>']
        const seatList = this.selectedSeats.map((seat) => {
            // console.log("seat to list:", seat)
            const seatPosition = seat.seat_obj.dataset.desc.split(' ')
            const onOrder = (jQuery(seat.seat_obj).hasClass('O')) ? ' on-order' : ''
            const seat_id = seat.seat_av_id
            const price_value_id = seat.seat_obj.dataset.price
            const pricetype = seat.pricetype
            // console.log("sending for pricing...", seat.pricetype, price_value_id)
            const amount = Seat.findSeatPriceOnPriceType(window.pricing, seat.pricetype, price_value_id)

            return (
                `<li class="seat-picked${ onOrder }" data-seat_id="${ seat_id }" data-price_type_id="${ seat.pricetype }" data-price_value_id="${price_value_id}">
                <span class="listsection">${ seatPosition[0] }</span> <span class="listrow">${ seatPosition[1] }</span> 
                <span class="listseat">${ seatPosition[2] }</span> 
                <span class="listseatpricetype">${ this.renderPriceTypeLabel(pricetype)[0] }</span> 
                <span class="listamount">${ amount }</span> 
                <a href="#" arial-label="Remove this seat selection" title="Remove seat" data-seat_id="${ seat_id }" class="seat-list__seat-remove">
                <svg aria-hidden="true" focusable="true" data-prefix="fas" data-icon="close" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" x="0px" y="0px" xml:space="preserve"><path fill="#121212" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                </a></li>`
            )
        })
        const seatListFinal = seatListLabels.concat(seatList)
        jQuery('.seats-selected .selections').html(seatListFinal.join(' '))
        jQuery('.totalPrice').text('$'+totalPrice.toFixed(2))
        jQuery('.totalCount').text(totalCount)
        if(seatList.length == 0) {
            jQuery('.seats-selected').hide()
        } else {
            jQuery('.seats-selected').fadeIn()
        }
    },

    selectedSeatsTotalCost() {
        const priceArray = this.selectedSeats.map((seat) => {
            // console.log("this is seat:", seat)
            const pricetype_id = seat.pricetype
            const pricevalue_id = seat.seat_obj.dataset.price
        
            return  parseFloat(Seat.findSeatPriceOnPriceType(window.pricing, pricetype_id, pricevalue_id).replace( /^\D+/g, ''))
        })
        return priceArray.reduce((a,b) => a + b, 0)
    },

    seatOnMapClicked($seat) {
        console.log("this seat clicked", $seat[0].dataset.seatid)
        $seat.toggleClass('active')
        if($seat.hasClass('active')) {
            
           // console.log(this.selectedSeats)
           this.openPriceTypeModal($seat, 'seatmap')
        } else {
            
            this.seatOnListRemove($seat[0].dataset.seatid)
        }
        if(localStorage.getItem('order_id')) {
            Order.removeSeatFromOrder(window.performance_id, $seat[0].dataset.seatid).then((response) => {
				const parsed = JSON.parse(response)
				Order.admissions = parsed.data[''].Admissions
				Order.orderDetails = parsed.data[''].Order	
			})
        }
        
    },

    renderPriceTypeLabel(id) {
        

        const theLabelRaw = Order.priceTypeLabels.find( priceType => priceType.key == id)
        const theOrder = Order.priceTypeLabels.indexOf(theLabelRaw)
        const theLabel = theLabelRaw['label'].replace(/ ?\(.*\) ?/g, '')
        return [ theLabel, theOrder ]
    },

    openPriceTypeModal($seat, option){
        // console.log("on openModal", $seat)

        const priceTypeFiltered = Object.values(window.priceTypes).filter((pricetype) => {
            if(pricetype.hasOwnProperty('price_type_id')) {
                return pricetype
            }
        })
        
        const priceTypeOutput = Object.values(priceTypeFiltered).map((pricetype) => {
            
                const value = Seat.findSeatPriceOnPriceType(window.pricing, pricetype.price_type_id.standard, $seat[0].dataset.price)

                if(value) {
                    const priceLabel = this.renderPriceTypeLabel(pricetype.price_type_id.standard)

                    return { 
                        price_type_id : pricetype.price_type_id.standard, 
                        order : priceLabel[1], 
                        label : priceLabel[0], 
                        description: pricetype.description.standard,
                        price_value: value,
                        promo: (Seat.checkForPromocode(window.pricing, pricetype.price_type_id.standard)) ? '<span class="dashicons dashicons-tag"></span>' : ''
                    }
                }
            
        })

        priceTypeOutput.sort((a,b) => (a.order > b.order) ? 1 : -1 )
        const renderOutput = priceTypeOutput.map( (pricetype) => {
            
            if(pricetype && pricetype.hasOwnProperty('price_type_id')) {
                return (
                    `<input type="radio" value="${ pricetype.price_type_id }" class="pricetype-option" name="pricetype_option" id="pricetype_${pricetype.price_type_id}" data-order="${pricetype.order}"><label for="pricetype_${pricetype.price_type_id}">${pricetype.promo} ${pricetype.label} – ${pricetype.description} ${ pricetype.price_value }</label>`
                )
            }
        })

        console.log('pTO - prerender', priceTypeOutput)
        jQuery('#seat_modal .price-content').html(renderOutput)
        jQuery('#seat_modal #setSeatPriceType').addClass(option)
        jQuery('#seat_modal #seat_av_id').val($seat[0].dataset.seatid)
        jQuery('#seat_modal #seat_id').val($seat[0].id)
        
        jQuery('#page_overlay').fadeIn()
        jQuery('#seat_modal').toggleClass('active')
        jQuery('#seat_modal').fadeIn()
    },

    listRemoveButtonClicked(seat_id) {
        SeatMap.selectedSeats
    },
    
    seatOnListRemove(seatID) {
        // console.log("seats before", this.selectedSeats, seatID)
        const removed = SeatMap.selectedSeats.filter((selectedSeat) => {
            return selectedSeat.seat_av_id !== seatID
        })
        this.selectedSeats = removed
        // console.log("seats after", SeatMap.selectedSeats)
        this.renderSelectedSeatsOnList()
    }
}


function Seat(seat) {
    this.message = seat.data.message.standard
    this.id = seat.data.seat_id.standard
    this.map_coords = { x_pos : seat.data.x_pos.standard[0], y_pos : seat.data.y_pos.standard[0] }
    this.status = seat.data.status.standard
    this.fill_color = seat.data.price_colour.standard
    this.description = seat.data.description.standard
}

Seat.findSeatPrice = function(obj, key, val) {
    // delete obj.state
    
    // if(Object.values(obj)[0][key].standard[0] === val) { 
    //     console.log("found at 0");
    //     return Object.values(obj)[0].totals.display[0];
    // } else if(Object.values(obj)[0][key].standard[1] === val) {
    //     console.log("found at 1");
    //     return Object.values(obj)[0].totals.display[1];
    // } else {
    //     for (let i = 1; i < Object.keys(obj).length; i++) {
    //         if (typeof obj[i] == 'object') {
    //             var seatPrice = this.findSeatPrice(obj[i], key, val);
    //             if (seatPrice) {
    //                 return seatPrice;
    //             }
    //         }
    //     }
    // }
}


// Seat.findSeatPriceOnPriceType = function(obj, val) {
//     delete obj.state
//     console.log("finding price", obj)
//     if(Object.values(obj)[0].price_zone_ids.standard[0] === val) {
//         console.log("found at 0")
//         return Object.values(obj)[0].totals.display[0]
//     } else if(Object.values(obj)[0].price_zone_ids.standard[1] === val) {
//         return Object.values(obj)[0].totals.display[1]
//     } else {
//         for (let i = 1; i < Object.keys(obj).length; i++) {
//             if(typeof obj[i] == 'object') {
//                 let seatPrice = this.findSeatPriceOnPriceType(obj[i], val)
//                 console.log("seatPrice", seatPrice, val)
//                 if(seatPrice) {
//                     return seatPrice
//                 }
//             }
//         }
//     }
// }

Seat.findBestAvailablePrice = function(price_id) {
    const result = Object.values(window.pricing).find(obj => {
        if(obj.hasOwnProperty('price_type_id')) {
            return obj.price_type_id.standard === price_id
        }
    })
    return result
}

// find the pricing object which matches price_type_id with the selected price_type_id, 
Seat.findSeatPriceOnPriceType = function(obj, val, val2) {
    delete obj.state
    // console.log("finding price in object:", Object.values(obj)[0])
    if(Object.values(obj)[0].price_type_id.standard === val) {
        
       // console.log("found pricing object")
        if(Object.values(obj)[0].price_zone_ids.standard[0] === val2) {
         //   console.log("found price at 0")
            return Object.values(obj)[0].totals.display[0]
        } else if(Object.values(obj)[0].price_zone_ids.standard[1] === val2) {
           // console.log("found price at 1")
            return Object.values(obj)[0].totals.display[1]
        }
        
    } else {
        
        for (let i = 1; i <= Object.keys(obj).length; i++) {
            if(typeof Object.values(obj)[i] == 'object') {
                const testAgain = { object: Object.values(obj)[i] }
             //   console.log("test agin: ", testAgain)
                let seatPrice = this.findSeatPriceOnPriceType(testAgain, val, val2)
                if(seatPrice) {
                    return seatPrice
                }
            } else {
               // console.log("not an object:", obj[i])
            }
        }

    }
    // console.log( "not found", val, val2, obj)
}


Seat.findPriceType = function (obj, key, val) {
    if(Object.values(obj)[0].price_zone_ids.standard[0] === val) {
        // console.log("found TYPE at 0");
        return Object.values(obj)[0].price_type_id.standard;
    } else if(Object.values(obj)[0].price_zone_ids.standard[1] === val) {
        // console.log("found TYPE at 1");         
        return Object.values(obj)[0].price_type_id.standard;
    } else {
        for (let i = 1; i < Object.keys(obj).length; i++) {
            if (typeof obj[i] == 'object') {
                var priceType = this.findPriceType(obj[i], key, val);
                if (priceType) {
                    return priceType;
                }
            }
        }
    }
}

Seat.checkForPromocode = function (obj, val) {
    

    const result = Object.values(obj).filter((price) => {
        if(price.hasOwnProperty('price_type_id')) {
            if( price.price_type_id.standard == val && price.promocodes.standard[0] ) {
                return price
            }
        }
    })
    if(result.length > 0) {
        // console.log("this has promo", result)
        return true
    }
    return false
}