// cookie functionality
function setCookie(cname, cvalue, exdays) {
  
  var expires = '';
  if(exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = "expires="+d.toUTCString();
  } else {
    expires = "";
  }
  
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// We also need a really short cookie for 'on hold' seats. Other one caclulates expiration in days.
function setSeatHoldCookie(cname, cvalue, minutes) {
  var expires = '';
  if(minutes) {
    var d = new Date();
    d.setTime(d.getTime() + (minutes * 60 * 1000));
    expires = "expires="+d.toUTCString();
  } else {
    expires = '';
  }  
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// adjusted this 
function checkCookie(cookieName, callback, error) {
  var val = getCookie(cookieName);
  if (val != "") {
    callback(val);
  } else {
    error(val);
  }
}

function checkCookieSimple(cookieName) {
  var val = getCookie(cookieName);
  if (val != "") {
    return true;
  } else {
    return false;
  }
}

