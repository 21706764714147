const Render = {

    renderUpdatedCheckoutTotal() {

    },
    
    renderSeatsOnOrder(seatsOnHold, performanceDetails, performanceRollup) {
        const id = window.performance_id

        const theSeats = Object.values(seatsOnHold).filter((seat) => {
            if (seat.hasOwnProperty("seat_id") && seat.performance_id.standard == window.performance_id && seat.state != '25') {
                return seat
            }
        })
        console.log("theSeats:", theSeats);
        const seatsFiltered = Object.values(theSeats).filter((seat) => {
            if(seat.state == '24' || seat.state == '32') {
                return seat
            }
        })
        
        const seatResults = seatsFiltered.map((seat) => {

            const showSeatCol = (window.seating_type == 'mixed') ? `<span>${ seat.seat.standard }</span>` : ` `
            const setSeatClass = (Object.values(theSeats).length > 1) ? 'remove' : 'final-remove'
            return (
                `<li ><span>${ seat.section.standard }</span> <span>${ seat.row.standard }</span> 
            ${ showSeatCol } <span> ${ formatter.format(seat.amount.standard) } ${ SeatMap.renderPriceTypeLabel(seat.price_type_id.standard)[0] }</span> 
            <span>${ Order.computeAdditionalCharges(seat) }</span>
            <span>${ formatter.format(seat.total.standard) }</span>
            <span class="remove"><a href="#" class="on-order__seat-${ setSeatClass }" data-seat_id="${seat.seat_id.standard}" data-admission_id="${seat.admission_id.standard}"><svg aria-hidden="true" focusable="true" data-prefix="fas" data-icon="close" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" x="0px" y="0px" xml:space="preserve"><path fill="#121212" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg></a></li>`
            )

        })

        console.log("this many seats", seatResults.length)
        const performances = Object.values(Order.performanceDetails).filter(performance => performance.hasOwnProperty('performance_id'))
        if (seatResults.length > 0 && performances.some(performance => performance.performance_id.standard == window.performance_id)) {
            const seatmapHeaders = ['<li class="headings"><span>Section</span> <span>Row</span> <span class="seat-column-title">Seat</span> <span>Seat Price</span> <span>Fees</span> <span>Total</span> </li>']
            const bestAvailHeaders = ['<li class="headings"><span>Section</span> <span>Row</span> <span>Seat Price</span> <span>Fees</span> <span>Total</span> </li>']
            const seatHeaders = (window.seating_type == 'mixed') ? seatmapHeaders : bestAvailHeaders
            const finalList = seatHeaders.concat(seatResults)
            const headerContent = `<div class="seatsOnHold__event">
            <div class="seatsOnHold__performance event_name">
            <h3 class="performance__title">${ performanceDetails[id].short_description.standard }</h3>
            <p class="performance__datetime">${ performanceDetails[id].start_date.display[0] }</p>
            </div>
            <div class="seatsOnHold__venue venue_name">
            <h4 class="venue__name">${ performanceDetails[id].venue_description.standard }</h4>
            <p class="venue__street">${ performanceDetails[id].venue_street.standard }</p>
            <p class="venue__addr2">
                <span class="venue__city">${ performanceDetails[id].venue_city.standard }</span>, 
                <span class="venue__state">${ performanceDetails[id].venue_state.standard.slice(-2) }</span> 
                <span class="venue__zip">${ performanceDetails[id].venue_zip.standard }</span>
            </p>
            </div>`

            const footerContent = `<div class="tickets__count-total">Total Tickets: 
            <span class="tickets__total-tickets">${ performanceRollup[id].admission_count.display }</span>
            </div>
            <div class="grand_total">Grand Total: 
            <span class="order_total">${ performanceRollup[id].total.display }</span>
            </div>`

            jQuery('#av_seatsOnHold .seatsOnHold__seatMapList').html(finalList.join(' '))
            jQuery('#av_seatsOnHold .seatsOnHold__header').html(headerContent)
            jQuery('#av_seatsOnHold .seatsOnHold__footer').html(footerContent)
        } else {
            // const button = [{id: 'cancel_order_confirm', class: 'btn-default', text: 'Ok'}]
            // window.modalMessageManager('#page_modal', 'Error: No Seats', "Your order has nothing on hold. You will need to start over with this event.", button )
        }
    },

    renderAddContactForm(contactItem, contact_id) {

        const form = `<form action="" class="add-new-contact" data-contact_id="${ contact_id }">
                        <h5>Add New Contact</h5>
                        <ul>
                        <li class="field col-6">
                            <label for="first_name" class="required">First Name</label>
                            <input type="text" name="first_name" id="first_name" required />
                        </li>
                        <li class="field col-6">
                            <label for="last_name" class="required">Last Name</label>
                            <input type="text" name="last_name" id="last_name" required />
                        </li>

                        <li class="field col-12">
                            <label for="email" class="required">Email Address</label>
                            <input type="text" name="email" id="email" required />
                        </li>
                        <li class="field col-12"><button class="" type="submit">Add Contact</button> | <button class="cancel-add-contact">Cancel</button><br /></li>
                        </ul>
                    </form>`

        jQuery(contactItem).closest('.info-edit').append(form)
    },

    renderAddAddressForm(addressItem, addressType) {
        const form = `<form action="" class="add-new-address" data-address_type="${ addressType }">
                <h5>Add New Address</h5>
                <ul>
                <li class="field col-12">
                    <label for="street_address1" class="required">Street Address</label>
                    <input type="text" name="street_address1" id="street_address1" required />
                </li>
                <li class="field col-7">
                    <label for="city" class="required">City</label>
                    ${ Purchase.insertCitySelect('city', 'city') }
                </li>
                <li class="field col-3">
                    <label for="state">State / Province</label>
                    <input type="text" name="state" id="state" />
                </li>
                <li class="field col-12">            
                    <label for="zip" class="required">Zip / Postal Code</label>
                    <input type="text" name="zip" id="zip" required />
                </li>
                <li class="field col-12">
                <label for="country" class="required">Country</label>
                <select id="country" name="country" required >
                    <option value="USA" selected>United States of America</option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">French Southern Ter</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">Netherland Antilles</option>
                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                    <option value="Republic of Serbia">Republic of Serbia</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">United Arab Emirates</option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">Vatican City State</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                </select>
              </li>
              <li class="field col-12"><button class="" type="submit">Add Address</button> | <button class="cancel-add-address">Cancel</button><br /></li>
            </ul>
                </form>`

        jQuery(addressItem).closest('.info-edit').append(form)
    },

    renderLoggedInPreCheckoutInfo() {
        // console.log("rendering checkout info")
        // const name = Account.defaultContact.contact_publish_name.standard

        const name = Order.billingContact.first_name.standard + ' ' + Order.billingContact.last_name.standard
        const billing_address = Order.billingAddress
        const shipping_address = Order.shippingAddress
        const contact = Order.billingContact
        const billingInfo = `<h2 class="checkout">Checkout</h2>
        <h3>Checkout Information</h3>
        <h8>Billing Name</h8>
        <p id="billingName">${ name }</p>

        <div id="email_info">
            <div class="display">
                <h8>Billing Email Address</h8>
                <p id="billingEmailAddress" data-contact_id="${ contact.contact_id.standard }">
                    ${ contact.email.standard }<br/>
                    <a href="#" class="change-user-data" data-formtype="contact">Change</a>
                </p>
            </div>
            <div class="info-edit"></div>
        </div>


        <div id="billing_info">
            <div class="display">
                <h8>Billing Address</h8>
                <p id="billingAddress" data-addr_id="${billing_address.address_id.standard}">
                    <span id="billingAddress1">${billing_address.street.display}</span><br/>
                    <span id="billingCity">${billing_address.city.display}</span> <span id="billingState">${billing_address.state.display}</span>, <span id="billingZip">${billing_address.zip.display}</span><br />
                    <a href="billing-address" class="change-user-data" data-formtype="billing-address">Change</a>
                </p>
            </div>
            <div class="info-edit"></div>
        </div>
        
        <div id="shipping_info">
            <div class="display">
                <h8>Shipping Address</h8>
                <input type="checkbox" id="account_use_billing_on_ship" name="account_use_billing_on_ship" data-set_ship_addr="${billing_address.address_id.standard}" > <label for="account_use_billing_on_ship" class="inline" style="display:inline">Use Billing Address</label>
                <p id="shippingAddress" data-addr_id="${shipping_address.address_id.standard}">
                    <span id="shippingAddress1">${shipping_address.street.display}</span><br/>
                    <span id="shippingCity">${shipping_address.city.display}</span> <span id="shippingState">${shipping_address.state.display}</span>, <span id="shippingZip">${shipping_address.zip.display}</span><br />
                    <a href="#" class="change-user-data" data-formtype="shipping-address">Change</a>
                </p>
            </div>
            <div class="info-edit"></div>
        </div>
        <div id="av_accountDeliveryOptions">
            <h8>How do you want to receive your tickets?</h8>
            <div class="delivery-methods">
            </div>
        </div>  
        <div class="options step4">
          <button id="account_info_confirmed">Next</button>
        </div>`

        jQuery('#av_accountPreCheckout').html(billingInfo)
        this.renderDeliveryMethods(Order.deliveryMethods, '#av_accountDeliveryOptions')
    },

    renderDeliveryMethods(order, parent) {
        // console.log("received by renderDeliveryMethods", order)
        const results = Object.values(order).map((deliveryMethod) => {
            if (deliveryMethod.hasOwnProperty("deliverymethod_id")) {
                // if theres one selected on the order already
                const selected = (deliveryMethod.deliverymethod_id.standard == Order.currentDeliveryMethod) ? ' checked' : ''
                // if there's an amount for the delivery method, add that to the label. I just hope value.amount.standard is the actual amount.
                const endstring = (deliveryMethod.amount.standard != '') ?
                    ' | $' + formatter(deliveryMethod.amount.standard) + '</label>' : '</label><br />';

                return (
                    `<input type="radio" class="delivery-option" name="deliveryOption" 
                    id="option_${ deliveryMethod.deliverymethod_id.standard }" name="delivery_option" 
                    value="${ deliveryMethod.deliverymethod_id.standard }" ${ selected }/> 
                    <label class="radio-label inline" for="option_${ deliveryMethod.deliverymethod_id.standard }"> 
                    ${deliveryMethod.name.display} ${endstring}`
                )
            }
        })
        jQuery(parent + ' .delivery-methods').append(results)
    },

    renderPayMethodLabel(id) {
        // console.log("the id", id, Order.priceTypeLabels)

        const thelabel = Order.payMethodLabels.find(payMethod => payMethod.key == id)
        // console.log("the label" , thelabel.label)
        return thelabel['label'].replace(/[{(}].*$/g, '')
    },

    renderPaymentMethods() {

        const filteredPayMethods = Object.values(Order.paymentMethods).filter((paymethod) => {
            if (paymethod.hasOwnProperty('payment_method_id')) {
                if(paymethod.type.standard != 'Account') {
                    return paymethod
                } else if(Order.hasOwnProperty('Customer') && Order.skipEmptyAccount(Order.Customer)) {
                    return paymethod
                }
            }
        })

        const paymentMethods = filteredPayMethods.map((paymentMethod) => {

            if (paymentMethod.hasOwnProperty('payment_method_id')) {
                // if(Order.skipEmptyAccount(Order.Customer)) {
                    const displayName = this.renderPayMethodLabel(paymentMethod.payment_method_id.standard)
                    const displayImage = (paymentMethod.icon.standard !== '') ? '<img src="https://tickets.courttheatre.org' + paymentMethod.icon.standard + '" class="paymentIcon" alt="' + displayName + '" /> ' : '<span class="paymentIcon_placeholder"></span>'

                    return (`${ displayImage }<input type="radio" class="payment-method" id="option_${ paymentMethod.payment_method_id.standard }"
                    data-type="${ paymentMethod.type.standard }" data-name="${ displayName }" 
                    name="payment_option" value="${ paymentMethod.payment_method_id.standard }" required /> 
                    <label class="radio-label inline" for="option_${ paymentMethod.payment_method_id.standard }">
                    ${ displayName }</label>`)
                // }
            }
        })

        jQuery('.payment_type_radio').html(paymentMethods.join(' '))
    },

    renderSecondaryPaymentMethods() {

        const filteredPayMethods = Object.values(Order.paymentMethods).filter((paymethod) => {
            if (paymethod.hasOwnProperty('payment_method_id')) {
                if(paymethod.type.standard != 'Account') {
                    return paymethod
                } else if(Order.hasOwnProperty('Customer') && Order.skipEmptyAccount(Order.Customer)) {
                    return paymethod
                }
            }
        })

        const paymentMethods = filteredPayMethods.map((paymentMethod) => {

            if (paymentMethod.hasOwnProperty('payment_method_id')) {
                // if(Order.skipEmptyAccount(Order.Customer)) {
                    const displayName = this.renderPayMethodLabel(paymentMethod.payment_method_id.standard)
                    const displayImage = (paymentMethod.icon.standard !== '') ? '<img src="https://tickets.courttheatre.org' + paymentMethod.icon.standard + '" class="paymentIcon" alt="' + displayName + '" /> ' : '<span class="paymentIcon_placeholder"></span>'
                    if( displayName != 'Account' ){
                        return (`${ displayImage }<input type="radio" class="sec_payment-method" id="sec_option_${ paymentMethod.payment_method_id.standard }"
                        data-type="${ paymentMethod.type.standard }" data-name="${ displayName }" 
                        name="sec_payment_option" value="${ paymentMethod.payment_method_id.standard }" required /> 
                        <label class="radio-label inline" for="sec_option_${ paymentMethod.payment_method_id.standard }">
                        ${ displayName }</label>`)
                    }
                // }
            }
        })

        jQuery('.sec_payment_type_radio').html(paymentMethods.join(' '))
    },

    renderAppendedPayments(){
        let results = ''
        if(Order.hasOwnProperty('payments') && Order.payments.length > 0) {
            results = Order.payments.map((payment) => {
            
                if(payment.hasOwnProperty('method')) {
                    const payDescription = Order.paymentMethods.find((paymethod) => paymethod.payment_method_id.standard === payment.method)
                    return `<li><h6>Payment Applied: ${ payDescription.description.standard } <a href="#" class="remove-payment" data-method_id="${ payment.method }">remove</a></h6> <span class="paymethod">${ formatter.format(payment.payamount) }</li>`  
                }
            })
        }
        return (`<div class="appended-payments">${ results }</div>`)
    },

    renderFinalCheckout(customerType) {

        // this.renderPaymentMethods()asas

        const FinalCheckout = {
            totalTickets: Purchase.calculateTotalTickets(Order.admissions),
            subTotal: Order.orderDetails.admission_total_amount.standard,
            additionalFees: Order.orderDetails.charge_net.standard,
            grandTotal: Order.orderDetails.grand_total.standard,
            addedGift: (Order.orderDetails.total_gift_amount.standard != '' && Order.orderDetails.total_gift_amount.standard != '0.00' ) ? `<li><h6>Your Gift <a href="#" class="remove-gift">remove</a></h6> <span class="addedGift">${formatter.format(Order.orderDetails.total_gift_amount.standard)}</span></li>` : '',
            appendedPayments: this.renderAppendedPayments(),
            totalDue: Purchase.calculateTotalDue(Order.orderDetails.grand_total.standard, Order.appendPaymentsTotal)
        }

        
        FinalCheckout.addedGiftCertificate = (Order.orderDetails.total_gift_certificate_amount.standard != '') ? `<li><h6>Gift Certificate <a href="#" class="remove-gift">remove</a></h6> <span class="addedGift">${formatter.format(Order.orderDetails.total_gift_certificate_amount.standard)}</span></li>` : ''
        const result = `
        <li><h6>Total Number of Tickets</h6> <span class="ticketTotal">${FinalCheckout.totalTickets}</span></li>
        <li><h6>Ticket Subtotal</h6> <span class="ticketCharges">${formatter.format(FinalCheckout.subTotal)}</span></li>
        ${ FinalCheckout.addedGift }
        <li><h6>Additional Fees</h6> <span class="additionalCharges">${ formatter.format(FinalCheckout.additionalFees) }</span></li>
        <li><h6>Grand Total</h6> <span class="totalDue">${ formatter.format(FinalCheckout.grandTotal) }</span></li>
        ${ FinalCheckout.appendedPayments }
        <li><h6>Total Due</h6> <span class="totalDue">${ formatter.format(FinalCheckout.totalDue) }</span></li>`

        // need to add render of all performances and seats on the order.

        jQuery('.performances-on-order .performances').html(this.renderPerformancesForFinalCheckout())
        jQuery('.orderSummary').fadeOut('fast')
        jQuery('.orderSummary').html(result)
        jQuery('.orderSummary').fadeIn()

    },

    renderPerformancesForFinalCheckout() {
        // console.log("rendering performances...")

        const results = Object.values(Order.performanceDetails).map((performance) => {
            // console.log("the formance", performance)
            if (performance.hasOwnProperty('performance_id')) {
                // console.log("performance: ", performance)
                return (
                    `<div class="performance" data-perform_id="${ performance.performance_id.standard }">
                        <div class="performance__details">
                            <h7 class="performance__name">${performance.short_description.standard}</h7>
                            <p class="performance__date">${performance.start_date.display[0]}</p>
                        </div>
                        <div class="performance__venue-details">
                            <h8 class="venue-name">${performance.venue_description.standard}</h8>
                            <p class="venue-address">${performance.venue_street.standard}<br />${performance.venue_city.standard} ${performance.venue_state.standard.slice(-2)}, ${performance.venue_zip.standard}<br /></p>
                        </div>
                        <ul class="final-seat-list">
                            ${ this.renderFinalSeats(performance.performance_id.standard) }
                        </ul>
                    </div>`
                )
            }
        })
        // console.log("final performance render", results)
        return results.join(" ")
    },

    // Dropdown at checkout so users can change pricetype
    renderPriceTypeDropdown(selectedSeat, selectedPricetype) {

        // Get clean price types
        const priceTypesFiltered = Object.values(window.priceTypes).filter((pricetype) => {
            if(pricetype.hasOwnProperty('price_type_id' )) {
                return pricetype
            }
        })

        // Combine price type with the price label, which also provides the order
        const priceTypesSortable = priceTypesFiltered.map((pricetype) => {
            const labelInfo = SeatMap.renderPriceTypeLabel( pricetype.price_type_id.standard )
            return {
                price_type_id: pricetype.price_type_id.standard,
                order: labelInfo[1],
                label: labelInfo[0],
            }
        })

        // Sort price types by label order
        priceTypesSortable.sort((a,b) => (a.order > b.order) ? 1 : -1 )

        // Output for dropdown
        const priceTypeOptions = priceTypesSortable.map((pricetype) => {

            if(pricetype.price_type_id === selectedPricetype) {
                return `<option selected value="${ pricetype.price_type_id }"> ${ pricetype.label } </option>`
            } else {
                return `<option value="${ pricetype.price_type_id }"> ${ pricetype.label } </option>`
            }
            
        })

        // Wrap it in a <select>
        const selectInput = `<select class="priceType_select" data-seat_id="${ selectedSeat }"> ${ priceTypeOptions} </select>`

        return selectInput
    },

    renderFinalSeats(performance_id) {
        const removeButton = '<svg aria-hidden="true" focusable="true" data-prefix="fas" data-icon="close" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" x="0px" y="0px" xml:space="preserve"><path fill="#121212" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>'
        const seatsFiltered = Object.values(Order.admissions).filter((seat) => {
            if(seat.state == '24' || seat.state == '32') {
                return seat
            }
        })
        const results = seatsFiltered.map((seat) => {
            if (seat.hasOwnProperty('performance_id') && seat.state == 24) {
                const setSeatClass = (Object.values(seatsFiltered).length > 1) ? 'remove' : 'final-remove'
                if (seat.performance_id.standard === performance_id) {
                    // console.log("seat: ", seat)
                    return (
                        `<li class="final-seat" id="final_seat_${ seat.seat_id.standard }" data-perf_id="${ performance_id }">
                            <span class="seat-desc">${ seat.section.standard } ${ seat.row.standard } ${ seat.seat.standard }</span>
                            <span class="cost-amount">${ seat.amount.display }</span>
                            <span class="seat-pricetype"> ${ this.renderPriceTypeDropdown( seat.seat_id.standard, seat.price_type_id.standard ) } </span>
                            <span class="fee-amount">${ Order.computeAdditionalCharges(seat) }</span>
                            <span class="total-amount">${ seat.total.display }</span>
                            <span class="remove-seat"><a class="on-order__seat-${setSeatClass}" data-seat_id="${ seat.seat_id.standard }" >${ removeButton }</a></span>
                        </li>`
                    )
                }
            }
        })

        return results.join(" ")
    },

    bestAvailbleDropDown() {
        let i = 1
        const string = ['<option value=""> </option>']
        for(i; i <= 18; i++) {
            const option = '<option value="' + i + '"> ' + i + ' </option>'
            string.push(option)
        }
        return string.join(' ')
    },

    renderBestAvailable(input) {

        const priceOptions = Object.values(input).filter((option) => {
            if(option.hasOwnProperty('price_type_id')) {
                return option
            }
        })

        const preRender = Object.values(priceOptions).map((option) => {
            
                const price_id = option.price_type_id.standard
                const prices = Seat.findBestAvailablePrice(price_id)
                const price_display = (prices.totals.display.length > 1) ? prices.totals.display[1] : prices.totals.display[0]
                const labelInfo = SeatMap.renderPriceTypeLabel(option.price_type_id.standard)
                console.log('best a option', option)
                return {
                    price_type_id: option.price_type_id.standard,
                    prices: prices,
                    price_display: price_display,
                    label: labelInfo[0],
                    order: labelInfo[1],
                    promo: (Seat.checkForPromocode(window.pricing, option.price_type_id.standard)) ? '<span class="dashicons dashicons-tag"></span>' : '',
                    promo_id: (Seat.checkForPromocode(window.pricing, option.price_type_id.standard)) ? ` data-promo_id="${ Performance.getThePromocode(option.price_type_id.standard) }"` : ''
                }
        })
        // console.log("best avail prerender", preRender, priceOptions)
        preRender.sort((a,b) => (a.order > b.order) ? 1 : -1 )

        const results = preRender.map((price) => {

                
            return(
                `<div class="price-option">
                    <select class="price-option__seats" id="bestAvail_${ price.price_type_id }" data-id="${ price.price_type_id }" ${ price.promo_id } name="">${ this.bestAvailbleDropDown() }</select>
                    <label for="bestAvail_${ price.price_type_id }">${ price.label }</label>
                    <div class="price-option__amount">${ price.price_display } ${ price.promo }</div>
                    
                </div>`
            )

        })
        
        jQuery('#bestAvailableOptions').html(results)
    }

}