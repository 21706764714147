// Number formatter for returning currency values
const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function enableStep($button, testval) {

	if (testval) {
		$button.removeClass('disabled');
		$button.prop('disabled', false);
	} else {
		$button.addClass('disabled');
		$button.prop('disabled', true);
	}
}

function slugify(term) {
	let newterm = term.replace(/\s/g, '_');
	newterm = newterm.toLowerCase();
	return newterm;
}

function scrollWindow(element) {
	// $element == target element
	console.log("scroll element: ", element.offset());
	element.fadeIn(function () {
		jQuery([document.documentElement, document.body]).animate({
			scrollTop: (element.offset().top)
		}, 500);
	});
}

function refreshTestbar() {

	// for testing
	jQuery.ajax({
		url: ct_localize.ajaxuri,
		type: 'POST',
		data: {
			action: 'testing_showUserName'
		},
		success: ((response) => {
			jQuery('#testBar .show-user-name').text(response)
		}),
		error: ((error) => {
			console.log(error)
		})
	})

	// for testing
	jQuery.ajax({
		url: ct_localize.ajaxuri,
		type: 'POST',
		data: {
			action: 'testing_sessionRemainingTime'
		},
		success: ((response) => {
			jQuery('#testBar .session-remaining').text(response)
		}),
		error: ((error) => {
			console.log(error)
		})
	})

	// for testing
	jQuery.ajax({
		url: ct_localize.ajaxuri,
		type: 'POST',
		data: {
			action: 'testing_showAVSessionId'
		},
		success: ((response) => {
			jQuery('#testBar .session-id').text(response)
		}),
		error: ((error) => {
			console.log(error)
		})
	})
	// end testing
}

// For "add gift" dynamic formatting.
function formatNumber(n) {
	// format number 1000000 to 1,234,567
	return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function clean() {
	jQuery('#msg').html('');
}


// For "add gift" dynamic formatting.
function formatCurrency(input, blur) {
	// appends $ to value, validates decimal side
	// and puts cursor back in right position.

	// get input value
	var input_val = input.val();

	// don't validate empty input
	if (input_val === "") {
		return;
	}

	// original length
	var original_len = input_val.length;

	// initial caret position 
	var caret_pos = input.prop("selectionStart");

	// check for decimal
	if (input_val.indexOf(".") >= 0) {

		// get position of first decimal
		// this prevents multiple decimals from
		// being entered
		var decimal_pos = input_val.indexOf(".");

		// split number by decimal point
		var left_side = input_val.substring(0, decimal_pos);
		var right_side = input_val.substring(decimal_pos);

		// add commas to left side of number
		left_side = formatNumber(left_side);

		// validate right side
		right_side = formatNumber(right_side);

		// On blur make sure 2 numbers after decimal
		if (blur === "blur") {
			right_side += "00";
		}

		// Limit decimal to only 2 digits
		right_side = right_side.substring(0, 2);

		// join number by .
		input_val = "$" + left_side + "." + right_side;

	} else {
		// no decimal entered
		// add commas to number
		// remove all non-digits
		input_val = formatNumber(input_val);
		input_val = "$" + input_val;

		// final formatting
		if (blur === "blur") {
			input_val += ".00";
		}
	}

	// send updated string to input
	input.val(input_val);

	// put caret back in the right position
	var updated_len = input_val.length;
	caret_pos = updated_len - original_len + caret_pos;
	input[0].setSelectionRange(caret_pos, caret_pos);
}

function getURLParam(paramName) {
	paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

function refreshAccountInfo() {
	Account.loadAccountContent('account_information')
		.then((response) => {
			const output = JSON.parse(response);
			const final = JSON.parse(output)
			if (final.hasOwnProperty('errorCode') == false) {
				Account.contacts = final.data.Contacts
				Account.addresses = final.data.Addresses
				Account.renderAccountInfo(final)

			}
		})
}

function modalMessageManager(modalName, header = '', message = '', buttons = []) {
	console.log("making modal", modalName)
	jQuery('#page_overlay').addClass('active')
	if (jQuery('#page_overlay').hasClass('active')) {
		jQuery('#page_overlay').fadeIn()
		const buttonsDisplay = buttons.map((button) => {
			console.log("the button", button)
			const dataAttr = (button.hasOwnProperty('data')) ? ` data-${button.data.label}="${button.data.content}" ` : ''
			if(!button.href) {
				return (
					`<button id="${ button.id }" class="${button.class}" ${dataAttr}>${ button.text }</button>`
				)
			} else {
				return (
					`<a id="${ button.id }" class="${button.class}" href="${button.href}" ${dataAttr}>${ button.text }</a>`
				)
			}
			
		})
		if (buttonsDisplay.length > 0) {
			jQuery(modalName + ' .buttons').html(buttonsDisplay.join(' '))
		}
		jQuery(modalName + ' .header').text(header)
		jQuery(modalName + ' .body').text(message)
		jQuery(modalName).addClass('active')
		jQuery(modalName).fadeIn()
	}
}

function idleTimer() {
	var t;
	window.onload = resetTimer;
	// window.onmousemove = resetTimer; // catches mouse movements
	// window.onmousedown = resetTimer; // catches mouse movements
	window.onclick = resetTimer; // catches mouse clicks
	window.onscroll = resetTimer;    // catches scrolling
	window.onkeypress = resetTimer; //catches keyboard actions

	function logout() {
		// window.location.href = '/action/logout';  //Adapt to actual logout script
		localStorage.removeItem('av_user')
		localStorage.removeItem('av_session')
		localStorage.removeItem('order_id')
		window.callAPI('session ended', false, {
			action: 'av_endSession'
		})
		// jQuery('#page_overlay').fadeIn()
		const button = [{ id: 'session_expired__close', class: 'btn btn-default', text: 'Ok' }]
		modalMessageManager('#page_modal', 'Session Expired', 'Your session has ended due to inactivty.', button)
	}

	function reload() {
		window.location = self.location.href; //Reloads the current page
	}

	function resetTimer() {
		clearTimeout(t);
		t = setTimeout(logout, 200000); // time is in milliseconds (1000 is 1 second)
	}
}

function showSpinCursor(parent, message) {
	const cursorBox = `<div id="cursor_box"><image src="/wp-content/themes/ct-2017/dist/images/ajax-loader.gif" /><span class="message">${message}</span></div>`
	jQuery(parent).append(cursorBox)
}

function isPage(type) {
	const string = window.location.pathname
	if (type === 'purchase') {
		const regex = new RegExp('^\/season-tickets\/purchase\/.*$')
		return regex.test(string)
	} else if (type === 'passwordRecovery') {
		const regex = new RegExp('^\/account\/password-recovery\/.*$')
		return regex.test(string)
	} else if (type === 'account') {
		const regex = new RegExp('^\/account\/.*$')	
		return regex.test(string)
	} else if (type === 'login') {
		const regex = new RegExp('^\/login\/.*$')
		return regex.test(string)
	}

}

function exitAccountPage() {
	// console.log("exit account called")
	if (!isPage('passwordRecovery') && isPage('account')) {
		const url = window.location.origin
		window.location.href = url + '/login/'
	}
}

function testForActiveUser() {
	const activeUser = localStorage.getItem('av_user')
	// console.log("active user", activeUser)
	if (activeUser) {
		return true
	}
	return false
}


function setWelcomeBanner() {
	let welcomeMessage = ''
	console.log("Setting welcome", testForActiveUser())
	if (testForActiveUser()) {
		console.log("logged in")
		Customer.getUserSessionInfo('first_name').then((response) => {
			console.log("** THIS SHOULD FIRE: first name", response)
			welcomeMessage = `Welcome back ${ response } | <a href="/login/" id="signin_toggle" class="account-tab__logout">Sign Out</a>`
			console.log('welcome mssg', welcomeMessage)
			jQuery('#account_tab #welcome').html(welcomeMessage)
		})
	} else {
		console.log("logged out")
		welcomeMessage = `Welcome Guest | <a href="#" id="signin_toggle" class="account-tab__login">Sign In</a>`
		jQuery('#account_tab #welcome').html(welcomeMessage)
	}

}

function setLoginAlert(message, source) {
	if (source == 'modal') {
		jQuery('#login_modal .alert-message').text(message)
	} else {
		jQuery('#loginResponse').text(message)
	}
}