const Customer = {

    getUserBillingAddress() {
        return window.callAPI('User Billing Address: ', false, { action: 'accountMethods', method: 'av_getCustomerBillingAddress' })
         
    },

    av_getCustomerInfoForCheckout( cust_id ) {
        const result = window.callAPI('Info for checkout', true, { action: 'accountMethods', method: 'av_getCustomerInfoForCheckout', id: cust_id })
        return result
    },

    addNewCustomer(values) {
        // const data = JSON.parse(values)
        values.action = 'accountMethods'
        values.method = "av_setNewUser_v3"
        console.log("add customer", values)
        return window.callAPI("add User", true, values)
    },
    addNewGuestCustomer(values) {
        // const data = JSON.parse(values)
        values.action = 'accountMethods'
        values.method = "av_setNewGuestUser_v3"
        console.log("add customer", values)
        return window.callAPI("add User", true, values)
    },

    loginUser(inputUser, inputPass) {
        const result = window.callAPI( "login", false, { action: "av_userAuthenticate", pass: inputPass, user: inputUser })
        return result
    },

    validateModalLogin(source) {
        return new Promise((resolve, reject) => {
            setLoginAlert('', source)
  
            const parent = (source === 'modal') ? jQuery('#login_modal') : jQuery('#page_login')
            
            // clear existing errors
            parent.find('error').removeClass('error')
            // is username empty?
            if (parent.find('#userName').val() !== '') {
                // is password empty?
                if(parent.find('#userPass').val() !== '') {
                    // We passed.
                    resolve(true)
                } else {
                    parent.find('#userPass').focus()
                    setLoginAlert("Please enter a password", source)
                }
            } else {
                parent.find('#userName').focus()
                setLoginAlert("Please enter a valid email address", source)
            }
            reject(false)
            
        })
    },

    testloginResponse(response) {
        if(response.hasOwnProperty('exception')) {
            return false
        }
        return true
    },

    getCustomerID() {

    },

    getCustomerName() {

    },

    avRejectedLogin(exception, source) {
        if(exception.type === 'Security' && exception.number === 2001) {
            setLoginAlert("The email address or password are invalid.", source)
        } else if( exception.type === 'Security' && exception.number === 2004 ) {
            setLoginAlert("You've made too many invalid login attempts.", source)
        }
    },

    openChangePassword() {
        // console.log("open pw modal")
        const id = jQuery('#page_login #userLogin #userName').val()
        jQuery('#setNewPassword #userId').val(id)
        jQuery('#page_overlay').addClass('active')
        jQuery('#password_resetter').addClass('active')
        jQuery('#page_overlay').fadeIn('fast')
        jQuery('#password_resetter').fadeIn()
    },

    getUserSessionInfo(key) {
        const result = window.callAPI("Get User Session Info", true, {action: 'getUserSessionInfo', param: key})
        return result
    },

    getCustomerInfo( customer ) {
        const result = window.callAPI("Get customer info", false, {
            action: 'accountMethods',
            method: 'av_getCustomerInfo',
            customer_id: customer
        })
        return result
    },

    getAccountInfo( customerid ) {
        const results = window.callAPI("Account Info", true, {
            action: 'accountMethods',
            method: 'av_getAccountInfo',
            customer_id: customerid
        })
        return results
    },

    sendPasswordRecovery( input ) {
        const results = window.callAPI("Password Recovery", true, {
            action: 'accountMethods',
            method: 'av_passwordRecovery',
            userid: input.userName
        })
        return results
    },

    updateGuestEmail( customerID, newEmail, firstName, lastName) {
        const results = window.callAPI("Update Guest Email", true, {
            action: 'accountMethods',
            method: 'av_updateGuestEmail',
            id: customerID,
            email: newEmail,
            fname: firstName,
            lname: lastName
        })
        return results
    },
    
    updateGuestEmail_v2( customerID, newEmail, firstName, lastName) {
        const results = window.callAPI("Update Guest Email", true, {
            action: 'accountMethods',
            method: 'av_updateGuestEmail_v2',
            id: customerID,
            email: newEmail,
            fname: firstName,
            lname: lastName
        })
        return results
    }
}

