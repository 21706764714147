(function ($) {


	// This is all click and 'on change' events...mostly

	// This is the ONLY document.ready call in the JS, so those 
	// events are here too (init purchase page stuff)



	///// ******* READY ***** /////

	$(document).ready(function () {

		// Sets the input mask
		$("#creditCardDate").mask("99/99")

		// Flag for seeing if user signs in during purchase
		window.loginByPurchaseFunnel = false;

		// 'Welcome Guest' or 'Welcome {username}' banner at top of page. 
		setWelcomeBanner()

		// we end the session on ilde after 20 minutes.
		// idleTimer();

		// if user is logged in
		// if (localStorage.getItem('av_user') || localStorage.getItem('av_session')) {

		// 	console.log("calling prepurchase from found login")
		// 	Purchase.initAccountPrePurchaseInfo()
		// }

		// Refactoring may have remove this need (relocated passwordRecovery)
		// so it's outside of the Account directory
		if (isPage('account') && !isPage('passwordRecovery')) {
			const user = localStorage.getItem('av_user')
			if (!user) {
				console.log('user no bueno', user)
				const url = window.location.origin
				window.location.href = url + '/login/'
			}
		}

		if($('#av_checkout').hasClass('active')) {
			$('.account-tab__logout').addClass('disabled')
		} else {
			$('.account-tab__logout').removeClass('disabled')
		}

		// To avoid FOUC, we load content with 0 opacity and flip it here. 
		$('.seats-selected, .options.step-2-alt, .shipping-info-guest, #page_modal, #av_shoppingCart, #av_selectedSeats, #av_account, #av_accountPreCheckout, #av_seatsOnHold, .options.step-2, #av_guestPreCheckout, #av_checkout, #login_modal, #creditCardForm, #secCreditCardForm, #giftCertificateForm, #secGiftCertificateForm, #accountBalanceForm, #guestDeliveryMethod, #secondaryCheckoutPayment').hide(function () {
			$(this).css('opacity', '1');
		});

		// Works with select2js to create custom dropdowns (this is the data)
		// function getCityList() {
		//   Order.getCitiesList().then((response) => {
		//     Order.cityList = JSON.parse(response)
		//   })
		//   .catch((error) => {
		//     console.log(error)
		//   })
		// }

		// Init the purchase page, get general info from API store it in the Order
		if (isPage('purchase')) {
			Order.getGifts().then((response) => {
					const parsed = JSON.parse(response)
					console.log("gifts response", parsed)
					Order.gifts = parsed.data.SearchResults
					const giftDescription = Object.values(parsed.data.SearchResults).find(gift => gift.name.standard === "Court-Online Giving")
					console.log("giftDescription", giftDescription)
					$('#giftDescription').text(giftDescription.description.standard)
				})
				.catch((error) => {
					console.log(error)
				})
		}


		// certain functions run on document.ready only on certain pages. Cheap way of doing that.
		if (isPage('purchase')) {
			
			// On Purchase page load, show the seatmap or the best available options
			if (window.seating_type === 'mixed') {
				$('.seatmap-overlay').fadeIn(function () {
					$(this).addClass('active')
					showSpinCursor('#av_seatmap', 'One moment, we are loading your performance.')
				})
				$('#av_bestAvail').hide()
				Purchase.initializePurchasePage()
				
				
				// refreshTestbar()
			} else if (window.seating_type === 'general admission') {
				$('#av_seatmap').hide()
				showSpinCursor('#av_bestAvail', 'One moment, we are loading your performance.')
				Purchase.initialBestAvailable()

				// refreshTestbar()
			}
		}

		// Forms to validate on submit
		$('#newAccountForm').validate()
		$('#checkoutPaymentForm').validate()


		// Welcome banner logout link
		$(document.body).on('click', '.account-tab__logout', function (e) {
			e.preventDefault()

			localStorage.removeItem('av_user')
			localStorage.removeItem('av_session')
			testForActiveUser()
			if(isPage('Account')) {
				exitAccountPage()
			}
			setWelcomeBanner()
		})

		// Welcome banner login link
		$(document.body).on('click', '.account-tab__login', function (e) {

			e.preventDefault()
			if (!isPage('login')) {
				if (!testForActiveUser()) {
					$('#login_modal').fadeIn(function () {
						$(this).addClass('active')
					})
				}
			} else {
				$('#userLogin #userName').focus()
			}
		})

		// Logged in user wants to change billing/shipping address or email
		$(document.body).on('click', 'a.change-user-data', function (e) {
			e.preventDefault()
			$('.info-edit').html('')
			Purchase.prepurchaseChangeInfo($(this).data('formtype'))
		})

		// Logged in user wants add a new address to the account
		$(document.body).on('click', '.add-info.new-billing-address, .add-info.new-shipping-address', function (e) {
			e.preventDefault()
			const type = $(this).data('address_type')
			Render.renderAddAddressForm($(this), type)
		})

		// Logged in user wants add new contact to the account
		$(document.body).on('click', '.add-info.new-contact', function (e) {
			e.preventDefault()
			Render.renderAddContactForm($(this))
		})

		// Logged in user CANCELS changing the billing/shipping address
		$(document.body).on('click', 'button.cancel-edit-address, button.cancel-edit-contact', function (e) {
			e.preventDefault()
			// console.log("this parent: ", $(this).closest('div.info-edit'))
			$(this).closest('div.info-edit').html('')
		})

		// Logged in user cancels adding a new address to customer
		$(document.body).on('click', 'button.cancel-add-address', function (e) {
			e.preventDefault()
			$(this).closest(".add-new-address").remove()
		})


		
		// Logged in user cancels adding a new contact to customer
		$(document.body).on('click', 'button.cancel-add-contact', function (e) {
			e.preventDefault()
			$(this).closest('.add-new-contact').remove()
		})

		// Some clicks send user back to the top of the buying funnel
		$(document.body).on('click', '#return_season_tickets', function (e) {
			e.preventDefault()
			const url = window.location.origin

			window.location.href = url + '/season-tickets/'
		})

		// Some errors allow the user to go back to the previous page
		$(document.body).on('click', '#return_previous', function (e) {
			e.preventDefault()
			history.back()
		})

		// Checkout - user initiated a second payment method, but cancelled
		$('#cancelSecondPayment').on('click', function(e) {
			e.preventDefault()

			$('#secondaryCheckoutPayment').fadeOut()
			$('#secondaryCheckoutPayment input').each( function() {
				$(this).val('')
			})
			
			$('input[name="sec_payment_option"]').prop('checked', false)
			$('#secCreditCardForm, #secGiftCertificateForm').hide()
			$('#cancelAccountBalance, #applyAccountBalance').prop('disabled', false)
		})


		// Add Gift (a donation in dollars) to Order
		$('#sendAddGift').on('click', function (e) {
			e.preventDefault()
			if ($('input#addGiftDollar').val() !== '') {
				const amount = $('input#addGiftDollar').val().replace(/\$/g, '')
				// console.log("pledge amount:", amount)
				Purchase.addGiftToOrder(amount)
					.then((response) => {
						const parsed = JSON.parse(response)
						const parsed2 = JSON.parse(parsed)
						// console.log("add gift and amount response:", parsed2)
						Purchase.updateWorkingOrderGift(parsed2)
					})
					.then(() => {
						Render.renderFinalCheckout('any')
					})
					.catch((error) => {
						console.log(error)
					})
			} else {
				$('input#addGiftDollar').val('Invalid');
			}
		})

		// Remove a Gift added to the Order
		$(document.body).on('click', 'a.remove-gift', function (e) {
			e.preventDefault()
			Purchase.removeDonation()
				.then((response) => {
					const parsed = JSON.parse(response)
					const parsed2 = JSON.parse(parsed)
					// console.log("Remove donation response: ", parsed2)
					return parsed2
				}).then((parsed2) => {
					Purchase.updateWorkingOrderGift(parsed2)
				}).then(() => {
					Render.renderFinalCheckout('any')
					$('#addGiftDollar').val('');
				})
				.catch((error) => {
					console.log(error)
				})
		})

		// Input formatting on the Gift dollar amount field
		$("input#addGiftDollar, input#applyAmount").on({
			keyup: function () {
				formatCurrency($(this));
			},
			blur: function () {
				formatCurrency($(this), "blur");
			}
		});

		// Purchase funnel, user is already logged in can skip to checkout
		$('.skip-account').on('click', function (e) {
			const data = { 
				action: 'orderMethods',
				method: 'av_updateAfterLogin',
				customer_id: localStorage.getItem('av_user'),
				order_id: Order.order_id
			}
			Order.updateAfterLogin(data).then((response) => {
				const parsed = JSON.parse(response)
				
				console.log("updateAfterLogin...", parsed)
				Purchase.applyUpdate(parsed)
			})

			Purchase.initAccountPrePurchaseInfo('init', '', fadeinPreCheckout)
		})

		//  I'm not sure I need this....
		function fadeinPreCheckout() {
			$('#av_accountPreCheckout').fadeIn()
		}

		// Close the login modal
		$('#closeLoginModal').on('click', function (e) {
			e.preventDefault();
			$('#login_modal').fadeOut(function () {
				$(this).removeClass('active')
			});
		})

		// Close Seat select modal
		$('.seat-modal__close-modal').on('click', function (e) {
			e.preventDefault()
			$('#seat_modal').fadeOut()
			$('#page_overlay').fadeOut()
			$('.seatmap-overlay').fadeOut()
			const theseat = $('input#seat_id').val()
			console.log('the seat tocance', theseat)
			$('#' + theseat).removeClass('active')
		})

		// Declined Terms & Conditions
		$('.termsconditions-modal__decline').on('click', function (e) {
			e.preventDefault()
			$('#tc_accept').val('0')
			$('#tc_modal').fadeOut(function () {
				$('#page_overlay').fadeOut()
				$('#tc_modal').removeClass('active')
			})
		})

		// The ticket reservations have expired. Need to reload the page.
		$(document.body).on('click', '#reload_expired', function(e) {
			e.preventDefault()
			const url = self.location.href;
			window.location = url;

			// console.log("go here", url)
		})
		$('#guest_use_billing_addr').on('change', function() {
			if($(this).prop("checked")) {
				$('.shipping-info-guest').fadeOut()
			}
			else {
				$('.shipping-info-guest').fadeIn()
			}
			
		})
		

	// END document.ready
	});


	// Reloads the purchase page using the promocode (returns discounts, etc.)
	$(document.body).on('click', '.promo-code__submit', function (e) {
		e.preventDefault()
		$('p.message').remove()
		const code = $('#promo-code__input').val()
		if (code && window.seating_type == 'mixed') {
			Performance.reloadPurchasePage(code)
			// refreshTestbar()
		} else {
			Performance.reloadBestAvailable(code)
			// refreshTestbar()
		}
	})

	// Users can change the price they selected just before checkout
	$(document.body).on('change', '.price-option__seats', function () {
		const total = $('.price-option__seats').each(function () {
			return $(this).val() + total
		})
		window.enableStep($('button.go__step2'), true)

	})

	// User has selected seats to put on the Order. Handles both reserve and Best available seating
	$('.go__step2').on('click', function () {

		if (window.seating_type === 'mixed' && SeatMap.selectedSeats.length > 0) {
			$('#av_seatmap .overlay').addClass('active')
			$('#av_seatmap .overlay').css('display', 'block')
			Purchase.initTicketPurchaseSeatMap()
				.then((response) => {
					const parsed = JSON.parse(response)
					// console.log("initTicketPurchase respone", parsed)
					Purchase.startWorkingOrder(parsed, 'init')
					// refreshTestbar()

				})
				.then(() => {

					// logged in users skip login, go right to next step (address confirm, delivery options)
					scrollWindow($('#av_seatsOnHold'))
					if (localStorage.getItem('av_user') && localStorage.getItem('av_session')) {
						
						$('.options.step-2-alt').show()
						// Purchase.initAccountPrePurchaseInfo('init')
						// refreshTestbar()

					} else {
						$('.options.step-2').show()
					}
				})
				.catch((error) => {
					console.log(error)
				})

		} else if (window.seating_type === 'general admission') {

			Purchase.initTicketPurchaseBestAvailable()
			.then((response) => {
				const parsed = JSON.parse(response)
				if(parsed.hasOwnProperty('exception')) {
					const message = parsed.exception.message
					const button = [{id: 'confirm_message', class: 'btn btn-default', text: 'Go back' }]
					modalMessageManager('#page_modal', 'Error', message, button )
					const error = true;
					return error
				} else {
					Purchase.startWorkingOrder(parsed, 'init')
				}
				// refreshTestbar()
				
			})
			.then((response) => {
				if(!response) {
					scrollWindow($('#av_seatsOnHold'))
					if (localStorage.getItem('av_user') && localStorage.getItem('av_session')) {

						$('.options.step-2-alt').show()
						// Purchase.initAccountPrePurchaseInfo('init')
						// refreshTestbar()
					} else {
						$('.options.step-2').show()
					}
				} else {
					return response
				}
			})
			.then((response) => {
				if(!response) {
					$('.city-list').each(function () {
						const formName = $(this).attr('name')
						const formId = $(this).attr('id')
						const replacement = Purchase.insertCitySelect(formId, formName)
						$(this).replaceWith(replacement)
					})
				} else {
					return response
				}
			})
			.then((response) => {
				if(!response) {
					$('.city-list').select2({
						dropdownParent: $('#guestPreCheckout'),
						tags: true
					})
				}
			})
			.catch((error, response) => {
				console.log(error, response)
			})
		}
	});

	// User has an Order started, wants to continue shopping other events.
	$('#continue_shopping').on('click', function(e) {
		e.preventDefault()
		const url = window.location.origin
		window.location.href = url + '/season-tickets/'
	})

	$(document.body).on('click', '#reloaded-purchase_continue', function(e) {
		$(this).closest('.modal').fadeOut(function() {
			$(this).removeClass('active')
		});
		$('#page_overlay').fadeOut(function () {
			$(this).removeClass('active')
		})
		$('.seatmap-overlay').fadeIn(function() {
			$(this).addClass('active')
		});
		$('.seatmap-step.go__step2').addClass('disabled', function() {
			$(this).attr('disabled', disabled)
		})
		
		if (localStorage.getItem('av_user') && localStorage.getItem('av_session')) {
			
			$('.options.step-2-alt').show()
			// Purchase.initAccountPrePurchaseInfo('init')
			// refreshTestbar()

		} else {
			$('.options.step-2').show()
		}
		scrollWindow($('#av_seatsOnHold'))
	})

	// User has reloaded a performance with an order started. Option 1: add/edit seats
	$(document.body).on('click', '#reloaded-purchase_edit-seats', function(e) {
		
		if(Order.hasOwnProperty('admissions')) {

			const admissions = Object.values(Order.admissions).filter((admission) => {
				if (admission.hasOwnProperty('admission_id')) {
					return admission
				}
			})

			console.log("admissions to add", admissions)
			
			for(let i = 0; i < admissions.length; i++) {
				const id = 'seat_'+ admissions[i].seat_id.standard
				const seat = {
					pricetype: admissions[i].price_type_id.standard,
					seat_id: id,
					seat_av_id: admissions[i].seat_id.standard,
					seat_obj: $('#'+id)[0],
					on_order: true,
					state: admissions[i].state
				}
				console.log("the seat", seat)
				if(SeatMap.hasOwnProperty('selectedSeats')) {
					SeatMap.selectedSeats.push(seat)
				} else {
					SeatMap.selectedSeats = [seat]
				}
			}

			SeatMap.renderSelectedSeatsOnList()
		}

		
		$(this).closest('.modal').fadeOut(function() {
			$(this).removeClass('active')
		});
		$('#page_overlay').fadeOut(function () {
			$(this).removeClass('active')
		})
		$('.seatmap-overlay').fadeOut(function() {
			$(this).removeClass('active')
		});
		// resets the Seats On Order display
		jQuery('#av_seatsOnHold .seatsOnHold__seatMapList, #av_seatsOnHold .seatsOnHold__header, #av_seatsOnHold .seatsOnHold__footer')
		.html('')
	})

	// Close button on a bunch of modals.
	$(document.body).on('click', '#closeModal.account-created__close-modal, #login_account.canel-create-account__close-modal', function (e) {
		e.preventDefault()
		$(this).closest('.modal').fadeOut(function () {
			$(this).closest('.modal').removeClass('active')
		})
		$('#login_modal').fadeIn(function () {
			$(this).addClass('active')
		});
	})

	// Closes the generic page modal (alerts, errors)
	$(document.body).on('click', '#close_modal', function(e) {
		e.preventDefault()
		$(this).closest('.modal').fadeOut(function() {
			$(this).removeClass('active')
		});
		$('#page_overlay').fadeOut(function () {
			$(this).removeClass('active')
		})
	})

	// Back out of creating a new account
	$(document.body).on('click', '#back_to_create_account.canel-create-account__close-modal', function (e) {
		e.preventDefault()
		$(this).closest('.modal').fadeOut()
		$(this).closest('.modal').removeClass('active')
		$('#page_overlay').fadeOut(function () {
			$(this).removeClass('active')
		})
	})


	/**** remove seats/admissions ****/
	// functions for 'remove' button on each seat in the list.
	$(document.body).on('click', 'a.seat-list__seat-remove', function (e) {
		e.preventDefault();
		const seatID = $(this).data('seat_id');
		// var admissionID = $(this).data('admission_id');
		$('#courtTheatre_seatmap .seat#seat_' + seatID).toggleClass('active')
		SeatMap.seatOnListRemove(seatID)
		if(localStorage.getItem('order_id')) {
			Order.removeSeatFromOrder(window.performance_id, seatID).then((response) => {
				const parsed = JSON.parse(response)
				Order.admissions = parsed.data[''].Admissions
				Order.orderDetails = parsed.data[''].Order	
			})
		}
	});

	// Popup to confirm removing performance from the Order
	$(document.body).on('click', 'a.on-order__seat-final-remove', function(e) {
		e.preventDefault()
		const seatID = $(this).data('seat_id');
		console.log("final seat id", seatID)
		// localStorage.setItem('final_seat', seatID)
		const buttons = [
			{
				id: 'confirm_final-seat_cancellation',
				class: 'btn btn-default',
				text: 'Remove Seat',
				href: '#',
				data: { label: 'seat', content: seatID }
			},
			{
				id: 'cancel_final-seat_cancellation',
				class: 'btn btn-default',
				text: 'Continue Order',
				href: '#',
			}
		]

		modalMessageManager('#page_modal', "Are You Sure You Want To Cancel?", "Removing this seat will cancel your order for this event.", buttons)
	})

	// User removes last/only seat On Order before checkout, it resets the performance entirely (reload page).
	$(document.body).on('click', '#confirm_final-seat_cancellation', function(e) {
		e.preventDefault()
		localStorage.removeItem('order_id');
		// const seat = localStorage.getItem('final_seat')
		const seat = $(this).data('seat')
		Order.removeSeatFromOrder(window.performance_id, seat).then((response) => {
			const parsed = JSON.parse(response)
			console.log("final seat removed", parsed)
			Order.admissions = parsed.data[''].Admissions
			Order.orderDetails = parsed.data[''].Order
			window.location.reload()
		})
		
	})
	
	// User chooses NOT to cancel Order by removing last seat.
	$(document.body).on('click', '#cancel_final-seat_cancellation', function(e) {
		e.preventDefault()
		$('#page_overlay').fadeOut(function() {
			$(this).removeClass('active')
		})
		$('#page_modal').fadeOut(function() {
			$(this).removeClass('active')
		})
	})

	// User removes a seat that is On Order, but it is not the final seat.
	$(document.body).on('click', 'a.on-order__seat-remove', function (e) {
		e.preventDefault();
		const seatID = $(this).data('seat_id');
		// var admissionID = $(this).data('admission_id');
		$('#courtTheatre_seatmap .seat#seat_' + seatID).toggleClass('active')
		SeatMap.seatOnListRemove(seatID)
		Order.removeSeatFromOrder(window.performance_id, seatID)
			.then((response) => {
				const parsed = JSON.parse(response)
				Order.admissions = parsed.data[''].Admissions
				Order.orderDetails = parsed.data[''].Order
				// console.log("new admissions", Order.admissions)

				// ** Need to flag if button is at top of order or at checkout...

				Render.renderSeatsOnOrder(Order.admissions, parsed.data[''].PerformanceDetails, parsed.data[''].PerformanceRollup, 'seatmap')
				if($('#av_checkout').hasClass('active')) {
					Render.renderFinalCheckout('account')
					$('#signin_toggle, #account-tab__logout').css('pointer-events', 'none')

				}
			})
	});

	// When a seat on the map is clicked run the pricing popup
	$('#courtTheatre_seatmap').on('click', '.seat.A, .seat.O, .seat.H', function (e) {
		e.preventDefault()
		var clicked = $(this)
		SeatMap.seatOnMapClicked(clicked)

		if ($(this).hasClass('active')) {
			$('.seatmap-overlay').fadeIn(function () {
				AlertModal.callSeatAlertModal(clicked)
			})
		}


	});

	// Close the alert modals
	$(document.body).on('click', '.seat-alert .seat-alert__close', function () {
		$('.seat-alert').fadeOut(function () {
			$('.seat-alert').removeClass('active');
		});
	})

	// There's a special 'performance modal' this closes the modal
	$(document.body).on('click', '#performance_modal .performance-modal__close', function () {
		$('#performance_modal, #page_overlay').fadeOut(function () {
			$('#performance_modal, #page_overlay').removeClass('active');

		});
	})



	// There's a special 'Terms and Conditions' modal. This allows user to accept T&Cs.
	$(document.body).on('click', '#tc_modal .termsconditions-modal__accept', function () {
		$('#tc_accept').val('1')
		$('#tc_modal, #page_overlay').fadeOut(function () {
			$('#tc_modal, #page_overlay').removeClass('active')
		})
		if ($('#tc_accept').val('1')) {
			Purchase.finalCheckout_v2()
		}
	})

	// The session has expired (20 minutes). Need to reload
	$(document.body).on('click', '#session_expired__close', function (e) {
		e.preventDefault()
		const url = self.location.href;
		window.location = url;
	})

	// Backs out of the seat pricing popup
	$(document.body).on('click', '.seat-modal__close', function (e) {
		e.preventDefault()
		$('#page_overlay').fadeOut()
		$('#seat_modal').fadeOut();
		$('#seat_modal .price-content').html('')
	})

	// Precheckout finished, go to next step (login/new account/guest checkout)
	$('.purchase-step.step3').on('click', function (e) {
		e.preventDefault();
		$('.seatmap-step.go__step2').prop('disabled', true)
		if (localStorage.getItem('av_user') || localStorage.getItem('av_session')) {
			$('.options.step-2').show();
		} else {
			// Purchase.initAccountPrePurchaseInfo('init')
			// refreshTestbar()
		}
		scrollWindow($('#av_account'));
	})

	// Logged in user confirms the Customer info is ready for checkout
	$(document.body).on('click', '#account_info_confirmed', function (e) {
		e.preventDefault();
		const updateData = {
			action: 'orderMethods',
			method: 'av_updateWorkingAccountOrder',
			customer_id: localStorage.getItem('av_user'),
			deliverymethod_id: document.querySelector('#av_accountDeliveryOptions input[name="deliveryOption"]:checked').value,
			billingAddress: $('#billingAddress').data('addr_id'),
			shippingAddress: $('#shippingAddress').data('addr_id'),
			contact_id: $('#billingEmailAddress').data('contact_id'),
			order_id: Order.order_id
		}
		// console.log("updateData: ", updateData)
		Order.updateWorkingAccountOrder(updateData)
		Render.renderPaymentMethods()
		Render.renderSecondaryPaymentMethods()
		Render.renderFinalCheckout('account')
		// refreshTestbar()
		$('#av_checkout').show(function() {
			$('#av_checkout').addClass('active');
			$('#account_info_overlay').addClass('active');
			$('#signin_toggle, #account-tab__logout').css('pointer-events', 'none')
		});
		scrollWindow($('#av_checkout'))
	})

	// User selects to create new account (on Purchase page)
	$('#createNewAccount').on('click', function (e) {
		e.preventDefault();

		$('#av_guestPreCheckout').hide();
		$('#av_createNewAccount').css('opacity', 1);
		$('#av_createNewAccount').show();
		scrollWindow($('#av_createNewAccount'));

	})

	// Toggles the create password fields to visible
	$(document.body).on('click', 'a.pswd-visible', function (e) {
		e.preventDefault()
		$(this).toggleClass('active')
		if ($(this).hasClass('active')) {
			$(this).siblings('input').attr('type', 'text')
			$(this).siblings('input').focus()
		} else {
			$(this).siblings('input').attr('type', 'password')
			$(this).siblings('input').focus()
		}
	})

	// Opens login modal
	$('#openUserLogin').on('click', function () {
		$('#login_modal').fadeIn(function () {
			$(this).addClass('active')
		});
		$('#av_createNewAccount').hide()
		$('#av_guestPreCheckout').hide()
	})


	
	// If guest checkout selected (instead of login or create account)
	$('#checkoutGuestProcess').on('click', function (e) {
		e.preventDefault()
		
		$('#av_createNewAccount').hide()
		$('#av_guestPreCheckout').show();

		// Clears out the customer on the Order. If a guest user nearly checks
		// out and bails this clears out the customer they created.
		Order.resetCustomerOnOrder(Order.orderDetails)
		.then((response) => {
			const parsed = JSON.parse(response)
			console.log(parsed)
		})
		.then(() => {
			scrollWindow($('#av_guestPreCheckout'));
		})
	})

	// Guest account created, user has selected delivery method
	$('#guest_delivery_confirmed').on('click', function (e) {
	  e.preventDefault();
	  const updateData = {
	    action: 'orderMethods',
		method: 'av_updateWorkingAccountOrder',
		customer_id: Order.Customer.data.Customer.customer_id.standard,
	    deliverymethod_id: document.querySelector('#av_guestDeliveryOptions input[name="deliveryOption"]:checked').value,
		billingAddress: Order.Customer.data.Customer.default_address_id.standard,
		shippingAddress: Order.Customer.data.Customer.default_address_id.standard,
		contact_id: Order.Customer.data.Customer.default_contact_id.standard,
		email_address: document.querySelector('#guestPreCheckout #billing_email').value,
		order_id: Order.order_id
	  }
	  
	  Order.updateWorkingAccountOrder(updateData).then((response) => {
		Render.renderPaymentMethods()
		Render.renderSecondaryPaymentMethods()
		Render.renderFinalCheckout('account')
		// refreshTestbar()
		$('#av_checkout').show(function() {
			$('#av_checkout').addClass('active');
			$('#signin_toggle, #account-tab__logout').css('pointer-events', 'none')
		});
		
		scrollWindow($('#av_checkout'))
	  })
	})

	// Change the display based on payment type selection
	$('#av_checkout').on('change', 'input[name="payment_option"]', function () {

		$('#creditCardForm,  #giftCertificateForm, #accountBalanceForm, #accountForm').css('display', 'none');
		let type = $(this).data('type')
		if (type === 'CreditCard') {
			$('#creditCardForm').css('display', 'flex')
		} else if (type === 'GiftCertificate') {
			$('#giftCertificateForm').css('display', 'flex')
		} else if (type === 'Account') {
			const balance = Order.displayAccountBalance()
			console.log("balance display", balance)
			$('#availableBalance').html(balance)
			$('#accountBalanceForm').css('display', 'flex')
			if(!$('#accountBalanceForm #applyAmount').val()) {
				$('#accountBalanceForm #applyAmount').val(Purchase.prePopAccountPayment(Order.getAccountBalance().amount.standard, Order.orderDetails.grand_total.standard))
			}
		}
		let name = $(this).data('name')
		$('.payMethod').html(name);
		$('#selectedPayMethod').val($('input[name="payment_option"]:checked').data('type'))
	})

	// If we need 2nd payment method, this does display changes for that module
	$('#av_checkout').on('change', 'input[name="sec_payment_option"]', function () {

		$('#secCreditCardForm, #secGiftCertificateForm').css('display', 'none');
		let type = $(this).data('type')
		if (type === 'CreditCard') {
			$('#secCreditCardForm').css('display', 'flex')
		} else if (type === 'GiftCertificate') {
			$('#secGiftCertificateForm').css('display', 'flex')
		}
		let name = $(this).data('name')
		$('.secPayMethod').html(name);
		$('#secSelectedPayMethod').val($('input[name="sec_payment_option"]:checked').data('type'))
	})

	// Logged in users set the billing address as the shipping address
	$(document.body).on('change', '#account_use_billing_on_ship', function (e) {
		e.preventDefault()
	
		if ($(this).is(':checked')) {
			$('#shippingAddress').css('display', 'none')
		} else {
			$('#shippingAddress').css('display', 'block')
		}
	
	})
	
	// Logged in users set the shipping email to use the billing address
	$(document.body).on('click', '#use_billing_addr', function () {
		if ($(this).is(':checked')) {
			$('input.optional, select.optional').not('.email').attr('disabled', true);
			$('label.optional').not('.email').addClass('disabled');
		} else {
			$('input.optional, select.optional').not('.email').removeAttr('disabled');
			$('label.optional').not('.email').removeClass('disabled');
		}
	});
	
	$('#use_billing_email').click(function () {
		if ($(this).is(':checked')) {
			$('input.optional.email').attr('disabled', true);
			$('label.optional.email').addClass('disabled');
		} else {
			$('input.optional.email').removeAttr('disabled');
			$('label.optional.email').removeClass('disabled');
		}
	});

	$('#applyGiftCard').on('click', function(e) {
		e.preventDefault()
		// $(this).attr('disabled', 'disabled')
		const card_no = $('#giftCardNo').val()
		const validation = $('#giftValidationCode').val()

		// this works b/c the button is only visible when gift certificate is selected.
		const method = $('input[name="payment_option"]:checked').val()
		Purchase.addGiftCardPayment(method, card_no, validation)
		.then((response) => {
			parsed = JSON.parse(response)
			Order.orderDetails = parsed

            
			Render.renderFinalCheckout('account')
		})
	})

	$('#applyAccountBalance').on('click', function(e) {
		e.preventDefault()
		$('.accountBalanceMessage').html('')
		$('input[name="payment_option"]').prop('disabled', true)
		
		const accountBalance = parseFloat(Order.getAccountBalance().amount.standard)
		const applyBalance = parseFloat($('#applyAmount').val().replace(/[^0-9.-]+/g,""))
		console.log('apply account balance called', applyBalance, accountBalance)
		const method = $('input[name="payment_option"]:checked').val()
		if( applyBalance > .009) {

			if( applyBalance > parseFloat(Order.orderDetails.grand_total.standard) ){
				$('.accountBalanceMessage').html('The amount cannot exceed the total purchase.')
				Purchase.prePopAccountPayment(accountBalance, parseFloat(Order.orderDetails.grand_total.standard))

			} else if( applyBalance <= accountBalance ) {
				Purchase.appendPartialPayment(method, applyBalance)
				const paymentIncomplete = Purchase.showSecondaryPayment()
				if(paymentIncomplete) {
					$('#secondaryCheckoutPayment .display-balance_due').html(formatter.format(paymentIncomplete.difference))
					$('#secondaryCheckoutPayment').fadeIn('slow')
				}
				$(this).prop('disabled', true)
				$('#cancelAccountBalance').prop('disabled', true)
			} else {
				console.log("Not enough funds in balance")
				$('.accountBalanceMessage').html('You cannot exceed your account balance.')
			}
		} else {
			console.log("Trying to apply no funds")
			$('.accountBalanceMessage').html('Please insert a higher amount.')
		}

	})

	$('#cancelAccountBalance').on('click', function(e) {
		e.preventDefault()
		const paymethod = $('input[name="payment_option"]:checked')
		const method = paymethod.val()
		paymethod.prop('checked', false)
		$('#creditCardForm, #giftCertificateForm, #accountBalanceForm, #accountForm').css('display', 'none');
		Purchase.removePartialPayment(method)
		$('#applyAmount').val('')
		$('input[name="payment_option"]').attr('disabled', false)
	})

	$(document.body).on('click', '.remove-payment', function(e) {
		e.preventDefault()
		const method = $(this).data('method_id')
		$('input[name="payment_option"]').prop('checked', false).prop('disabled', false)
		$('#creditCardForm, #secondaryCheckoutPayment, #secCreditCardForm, #secGiftCertificateForm, #giftCertificateForm, #accountBalanceForm, #accountForm').css('display', 'none');
		$('#secondaryCheckoutPayment input').each( function() {
			$(this).val('')
		})
		$('input[name="sec_payment_option"]').prop('checked', false)
		Purchase.removePartialPayment(method)
		$('#applyAmount').val('')
		$('#cancelAccountBalance, #applyAccountBalance').prop('disabled', false)
	})

	$(document.body).on('change','.priceType_select',function(e) {
		e.preventDefault()

		const newPriceType = $(this).val()
		const seat = $(this).data('seat_id')
		const performance_id = $(this).parent()	.parent('.final-seat').data('perf_id')
		Order.updateSeatPriceType(performance_id, seat, newPriceType).then((response) => {
			const parsed = JSON.parse(response)
			const parsed2 = JSON.parse(parsed)
			// console.log('after update seat...', parsed2)
			Order.orderDetails = parsed2.data.Order
			Order.admissions = parsed2.data.Admissions
			
			Render.renderFinalCheckout('account')
		})
	})

	$(document.body).on('click','#pw_changeConfirm',function(e) {
		e.preventDefault()
		const url = window.location.origin
		window.location.href = url + '/login/'
	})

	$(document.body).on('click','#confirm_message',function(e) {
		e.preventDefault()
		$('#page_overlay').fadeOut(function() {
			$(this).removeClass('active')
		})
		$('#page_modal').fadeOut(function() {
			$(this).removeClass('active')
		})
	})

	$(document.body).on('click', '#av_cancelPurchase', function(e) {
		e.preventDefault()

		const buttons = [
			{
				id: 'confirm_order_cancellation',
				class: 'btn btn-default',
				text: 'Cancel Order',
				href: '#',
			},
			{
				id: 'cancel_order_cancellation',
				class: 'btn btn-default',
				text: 'Continue Checkout',
				href: '#',
			}
		]

		modalMessageManager('#page_modal', "Cancel Order", "This will remove your order and release your seat reservation(s) for all events. Are you sure you want to cancel?", buttons)
	})
	

	$(document.body).on('click', '#confirm_order_cancellation', function(e) {
		e.preventDefault()
		localStorage.removeItem('order_id');
		window.location.reload()
	})
	
	$(document.body).on('click', '#cancel_order_cancellation', function(e) {
		e.preventDefault()
		$('#page_overlay').fadeOut(function() {
			$(this).removeClass('active')
		})
		$('#page_modal').fadeOut(function() {
			$(this).removeClass('active')
		})
	})
	$(document.body).on('click', '#cancelChangePassword', function(e) {
		e.preventDefault()
		$('#page_overlay').fadeOut(function() {
			$(this).removeClass('active')
		})
		$('#password_resetter').fadeOut(function() {
			$(this).removeClass('active')
		})
	})
}(jQuery));