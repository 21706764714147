jQuery('#subscription_prePurchase select').change(function() {
  
  let query = { 
    data1: jQuery('#subscription_prePurchase #data1').val() ,
    data2: [ jQuery('#subscription_prePurchase #data2').val() ],
    data3: [ jQuery('#subscription_prePurchase #data3').val() ],
  }
  
  const bundles = JSON.parse(localStorage.getItem('reserveBundles'));
  const data = [  ];
  
  for (var [key, value] of Object.entries(bundles)) {
    data.push(value);
  }
  
  const result = BundleFilters.filterData(data, query);
  // parseReserveBundlesFiltered(result);
  Bundles.renderFilteredBundles(result)
});

(function($) {
  $(document).ready(function() {
    $('.filters, .userCheckoutForm').hide(function() {
      $(this).css('opacity','1');
    });
  })
}(jQuery));


// ***** Click Events *****


// Get pricetypes and create dropdowns for each bundle.
jQuery(document.body).on('click', '#bundlesOnSale .buyBundle', function() {
  
  const loadprices = jQuery(this).data('bundle_id');
  const bundleData = []
  
  Bundles.getBundleInfo(loadprices).then((response) => {
      const parsed = JSON.parse(response)
      console.log("getBundleInfo", loadprices, parsed)

      Order.selectedBundle = parsed.data.bundle
      Order.bundlePricing = parsed.data.bundle_pricing
      Order.bundleLegends = parsed.data.legends
      Order.bundlePerformances = Object.values(parsed.data.performances).filter((performance) => {
        if (performance.hasOwnProperty('performance_id')) return performance
      })
      Order.bundlePricetypes = parsed.data.pricetypes
      Order.bundleSeatmap = parsed.data.seatmap

      const optionRange = (jQuery(this).hasClass('reserve')) ? { low: 2, high: 18 } : { low: 3, high: 18 }

      Bundles.renderBundlePriceTypes( Order.bundlePricetypes, loadprices, optionRange );
  })
  .catch((error) => {
    console.log(error);
  })
  
});

jQuery(document.body).on('click', '.closeBundleSeats', function() {
  Bundles.clearBundleOptions();
})

jQuery(document.body).on('click', '.bundlePurchase', function() {
    const $thisBundle = jQuery(this)
    const bundle_id = $thisBundle.data('bundle_id')
    const seatData = {}
    const $seatInput = $thisBundle.parent().parent('.bundleSeats')
    $seatInput.children('li').each(function() {
      const key = jQuery(this).find('select').data('pricetype_id')
      const seatCount = parseInt(jQuery(this).find('select').val())
      if(seatCount > 0) {
        seatData[key] = seatCount
      }
    })

    console.log("get this", seatData)
    Purchase.initBundleBestAvailable(bundle_id, Order.bundlePerformances, seatData)
    .then((response) => {
      const parsed = JSON.parse(response)
      console.log("bundles...", parsed)
    })
})

jQuery('#sub_flex').click(function(e) {
    e.preventDefault();
    
    Bundles.getFlexBundles()
    .then((response) => {
      const output = JSON.parse(response)
      
      localStorage.setItem('flexBundles', JSON.stringify(output.data.SearchResults));
      Bundles.renderFlexBundles(JSON.parse(localStorage.getItem('flexBundles')));
    })
    .catch((error) => {
      console.log(error);
    })
});

jQuery('#subReserve').click(function(e) {
    e.preventDefault();
    
    Bundles.getReserveBundles()
    .then((response) => {
      let output = JSON.parse(response);
      localStorage.setItem('reserveBundles', JSON.stringify(output.data.SearchResults));
      Bundles.renderReserveBundles(JSON.parse(localStorage.getItem('reserveBundles')));
    })
    .catch((error) => {
      console.log(error);
    })
});