(function ($) {
  function isPage(type) {
    const string = window.location.pathname
    if(type === 'purchase') {
      const regex = new RegExp('^\/season-tickets\/purchase\/.*$')
      return regex.test(string)
    } else if(type === 'account') {
      const regex = new RegExp('^\/account\/.*$')
      return regex.test(string)
    } else if(type === 'login') {
      const regex = new RegExp('^\/login\/.*$')
      return regex.test(string)
    }
    
  }
  function testForActiveUser() {
    const activeUser = localStorage.getItem('av_user')
    // console.log("active user", activeUser)
    if(activeUser) {
      return true
    }
    return false
  }
  
  function setWelcomeBanner() {
    let welcomeMessage = ''
    console.log("Setting welcome", testForActiveUser())
    if (testForActiveUser()) {
      console.log("logged in")
      Customer.getUserSessionInfo('first_name').then((response) => {
        console.log("first name", response)
        welcomeMessage = `Welcome back ${ response } | <a href="/login/" id="signin_toggle" class="account-tab__logout">Sign Out</a>`
        $('#account_tab #welcome').html(welcomeMessage)
      })
    } else {
        console.log("logged out")
        welcomeMessage = `Welcome Guest | <a href="#" id="signin_toggle" class="account-tab__login">Sign In</a>`
        $('#account_tab #welcome').html(welcomeMessage)
    }
    
  }

  // function setLoginAlert(message, source) {
  //   if (source == 'modal') {
  //     jQuery('#login_modal .alert-message').text(message)
  //   } else {
  //     jQuery('#loginResponse').text(message)
  //   }
  // }
  
  $(document).on('ready', function () {
    // if user is logged in

    if (localStorage.getItem('av_user') || localStorage.getItem('av_session')) {
      // console.log("calling prepurchase from found login")
      // Purchase.initAccountPrePurchaseInfo()
    }

    $(document.body).on('click', '#closeModal.account-created__close-modal, #login_account.canel-create-account__close-modal', function (e) {
      e.preventDefault()
      $(this).closest('.modal').fadeOut()
      $('#login_modal').fadeIn(function () {
        $(this).addClass('active')
      });
    })

    // login submit button clicked
    $('#userLogin #sendUserLogin').off('click').on('click', function (e) {
      e.preventDefault()
    

      // login on purchase or subscription purchase page
      if (! isPage('login')) {
        console.log("Login via modal")
        // just check for no blanks. we can refine this later. response is t/f
        Customer.validateModalLogin('modal')
          .then((response) => {
            if (response) {
              console.log("validate login response", response)
              // Send login to AV
              Customer.loginUser($('#userName').val(), $('#userPass').val())
                // They send a response
                .then((response) => {
                  const parsed = JSON.parse(response)
                  console.log("login response: ", parsed.response)
                  

                  // did it pass?
                  if (Customer.testloginResponse(parsed.response)) {
                    $('#login_modal').fadeOut()
                    
                    localStorage.setItem('av_user', parsed.response.data.customer_id.standard)
                    localStorage.setItem('av_session', parsed.session)

                    setWelcomeBanner()
                    Account.customer_id = parsed.response.data.customer_id.standard
                    // for testing
                    // refreshTestbar()
                    if(isPage('purchase')) {
                      
                      const updateData = {
                        action: 'orderMethods',
                        method: 'av_updateAfterLogin',
                        customer_id: Account.customer_id,
                        order_id: Order.order_id
                      }

                      Order.updateAfterLogin(updateData).then((response) => {
                        
                        const parsed = JSON.parse(response)
                        
                        // console.log("updateAfterLogin...", parsed)
                        Purchase.applyUpdate(parsed)

                        // Purchase.initAccountPrePurchaseInfo('init')
                        Purchase.initAccountPrePurchaseInfo('init')

                        $('#login_modal').fadeOut(function () {
                          $(this).removeClass('active')
                          $('#av_createNewAccount').hide()
                          $('#av_guestPreCheckout').hide()
                          $('#page_overlay').fadeOut()
                          $('#av_accountPreCheckout').fadeIn(function () {
                            $('#av_account').fadeOut();
                          });
                        })
                      })
                      .catch((error)=> {
                        console.log("error on login after auth", error)
                      })
                    }
                    
                  } else {
                    localStorage.removeItem('av_user')
                    localStorage.removeItem('av_session')
                    // pass error
                    console.log("found exception on login")
                    Customer.avRejectedLogin(parsed.response.exception, 'modal')
                  }
                })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        // login on /login page
        console.log("Login via page")
        Customer.validateModalLogin('page')
          .then((response) => {
            if (response) {
              console.log("validate login response", response)
              // Send login to AV
              Customer.loginUser($('#userName').val(), $('#userPass').val())
                // They send a response
                .then((response) => {
                  const parsed = JSON.parse(response)
                  console.log("login response: ", parsed.response)

                  // did it pass?
                  if (Customer.testloginResponse(parsed.response)) {
                    localStorage.setItem('av_user', parsed.response.data.customer_id.standard)
                    localStorage.setItem('av_session', parsed.session)
                    Account.customer_id = parsed.response.data.customer_id.standard
                    // for testing
                    const url = window.location.origin
                    if(window.location.search !== '?password-recovery=true') {
                      window.location.href = url + '/account/'
                    } else {
                      // window.location.href = url + '/account/#changePassword'
                    }

                  } else {
                    localStorage.removeItem('av_user')
                    localStorage.removeItem('av_session')
                    // pass error
                    console.log("found exception on login")
                    // find out if fail was due to expired password
                    if(parsed.response.exception.number != 2002) {
                      console.log(parsed.response.exception.number)
                      Customer.avRejectedLogin(parsed.response.exception, 'page')
                      const button = [{
                        id: 'close_modal',
                        class: 'btn-default',
                        text: 'Ok',
                        href: ''
                      }]
                      modalMessageManager('#page_modal', 'Login Error', parsed.response.exception.message, button)
                    } else {
                      console.log(parsed.response.exception.number)
                      Customer.openChangePassword()
                    }
                    
                  }
                })
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })

    // Close the login modal
    $('#closeLoginModal').on('click', function (e) {
      e.preventDefault();
      $('#login_modal').fadeOut(function () {
        $(this).removeClass('active')
      });
    })

  })

}(jQuery))